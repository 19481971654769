import http from "../http-common";
class DataService {
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList" );
  }
  GetFullMenu(){
    return http.instanceCommon.get("/Common/GetFullMenu" );
  }
  UnreadItems(params){
    return http.instanceCommon.get("/Notification/UnreadItems?" + params );
  }
  GetitemDetail(params){
    return http.instanceCommon.get("/Notification/GetItemDetail?" + params );
  }
  DeleteSampleData(){
    return http.instanceCommon.post("/Common/DeleteSampleData" );
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  GetUserWizard(){
    return http.instanceCommon.get("/Common/GetUserWizard");
  }
  async GetCompanyLogo(params){
    return await http.instanceCommon.get("/Common/GetCompanyLogo?" + params);
  }
  async GetIncompleteEntry(){
    return await http.instanceTimer.get("/GetIncompleteEntry");
  }
  async GetTimerProjectsByUserID(){
    return await http.instanceTimer.get("/GetTimerProjectsByUserID");
  }
  async LoadTasks(id){
    return await http.instanceTimer.get("/LoadTask?projectId="+id);
  }
  async SaveProjectInfo (info){
    return await http.instanceTimerModals.post("/SaveTimeEntry",info);
  }
  async GetBreakTimeList (){
    return await http.instanceTimer.get("/GetAllShiftBreakListing");
  }
  async GetTrackHours(){
    return await http.instanceTimer.get("/GetTrackHours");
  }
  async UnreadAllItems(params){
    return await http.instanceCommon.post("/Notification/UnreadAllItems?" + params)
  }
  async GetNotifications(params){ 
    debugger;
    return await http.instanceCommon.get("/Notification/GetNotifications?" + params)
  }
  async GetModuleList(){ 
    return await http.instanceCommon.get("/Common/ModuleList");
  }
  async DeleteByID(params){
    return await http.instanceCommon.post("/Notification/DeleteByID?" + params)
  }
  async GetBreaksDetails(url){
  
    return await http.instanceTimer.get("/GetBreaksDetails?" + url);
  }
  GetQuickAddDataListing() {
    return  http.instanceCommon.get("/Common/GetQuickAddLayoutList");
  }
  GetFormCustomLayout(params) {    
    return  http.instanceCommon.get("/FormLayout/GetForm?" + params);
  }
  async SaveDynamicForm(params) {
    return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
  }
}
export default new DataService();
