import { defineStore } from "pinia";
import { useloggedUserInfo } from "./NavUserInfo"
import  DataService   from "../services/DataService";
import {  formatter,ShowAlert } from "@/assets/common/vue-common.js"
import i18n from '../localization/index'
export const userTimerInfo = defineStore("TimeTracker", {
    state: () => ({
        timerCountInSec: 0,
        breakTimerCountInSec:0,
        timerInfo: '',
        timerProjectDetails:"",
        projects:[],
        tasks:[],
        breakTimerList:[],
        loading:false,
        timerRunResponse:'',
        timerClock:"00:00:00",
        breakTimerClock:"00:00:00",
        interval:"",
        breakInterval:"",
        breakNegative:false,
        trackHoursData:"",
        breakDetailsState:''
    }),
    getters: {
        getTimer: (state) => {
            return state.timerInfo
        },
        getProjectDetails:(state)=>{
            return state.timerProjectDetails
        },
        getProjectsByUser:(state)=>{
            return state.projects
        },
        getTasksFromProject:(state)=>{
            return state.tasks
        },
        getLoadingStatus:(state) =>{
            return state.loading
        },
        getBreakTimeList:(state)=>{
            return  state.breakTimerList;
        },
        getUserInformation:()=>{
         const  {user} = useloggedUserInfo();
         return user.profile;
        },
        getTimerRunResponse:(state)=>{
            return state.timerRunResponse
        },
        getTimerClock:(state)=>{
            return state.timerClock
        },
        getBreakTimerClock:(state)=>{
            return state.breakTimerClock
        },
        GetTrack:(state)=>{
            return state.trackHoursData
        },
        getbreakDetails:(state)=>{
            return state.breakDetailsState
        }
    },
    actions: {
        async submitProjectInfo(info) {
         
           // let param = 'projectId='+info?.projectId+'&trackDescription='+info?.trackDescription+'&taskId='+info?.taskId+'&isBillable=0&startTime='+info.startTime+'&stopTime='+info.stopTime+'&currentDate='+info.currentDate+'&timeformat='+info.timeformat+'&type='+info.type;
            this.loading = true
            try{
                const {data} =await DataService.SaveProjectInfo(info);
               this.timerRunResponse = data
               if(data.userMsg){
                debugger
                // let type = data.isInvalid ? 'danger' : 'warning'
                // ShowAlert(data.userMsg, type, true, "Alert");
                    if(data.userMsg=="stop")
                    {
                    ShowAlert(i18n.t('TimeEntryStopSuccessfully'), "success", true, i18n.t('Alert'));
                    }
                else if(data.userMsg=="TimerResumedAlready"){
                    ShowAlert(i18n.t('TimerResumedAlready'), "danger", true, i18n.t('Alert'));
                }
                else if(data.userMsg=="TimerStopped"){
                    ShowAlert(i18n.t('TimerStopped'), "danger", true, i18n.t('Alert'));
                }
                else if(data.userMsg=="TimerStartedAlready"){
                    ShowAlert(i18n.t('TimerStartedAlready'), "danger", true, i18n.t('Alert'));
                }
                else if(data.userMsg=="TimerPaused" && data.userMsg=="TimerPausedAlready"){
                    ShowAlert(i18n.t('TimerPausedAlready'), "danger", true, i18n.t('Alert'));
                }
                else if(data.userMsg=="AllowTrackingTimeOnHoliday_desc"){
                    ShowAlert(i18n.t('AllowTrackingTimeOnHoliday_desc'), "warning", true, i18n.t('Alert'));
                }
                else if(data.userMsg=="EtreesApprovedpending"){
                    ShowAlert(i18n.t('EtreesApprovedpending'), "warning", true, i18n.t('Alert'));
                }
                else{
                    ShowAlert(data.userMsg, "danger", true, i18n.t('Alert'));
                }
            }
            }catch(e){

            }
            this.loading = false
        },
        async getProjects() {
            this.loading =true
            this.projects = []
            try{
                const { data}= await DataService.GetTimerProjectsByUserID();
                
                this.projects = typeof data?.res =="object" ? data?.res: [];

               
            }catch(e){

            }
            this.loading = false
        },
        async loadTasks(id) {
            this.loading = true 
            try{
                const { data}= await DataService.LoadTasks(id);
                this.tasks = data.map((item)=>{
                    if(Number(item.secondAttribute) == 0){
                    item.$isDisabled =true 
                    }
                    return item
                })
                //console.log("checkk",data?.res)
            }catch(e){

            }
            this.loading = false 
        },
        async getTimerInfo(){
            this.loading = true 
            try{
           const {data} =  await DataService.GetIncompleteEntry();
             this.timerInfo = data?.result[0]
            }catch(error){
                console.log('error',error);
            }
            this.loading = false 
            
        },
        async getBreakTime(){
            this.breakTimerList = []
            this.loading = true
            try{
                const {data} = await DataService.GetBreakTimeList();
                
                data?.result?.map((item)=>{
                    this.breakTimerList.push({
                        name:item.breakDetail,
                        value:item.breakId,
                        breakDuration:item.breakDuration,
                        isBillable:item.isBillable,
                        isPaid:item.isPaid
                    })
                 })
            }catch(e){

            }
            this.loading = false
        },
        setTimerClock(sec){
            this.timerClock= formatter(sec)
        },
        startTimer(){
            clearInterval(this.interval);
            clearInterval(this.breakInterval);
           this.interval = setInterval(() => {
            this.timerCountInSec++
            this.setTimerClock(this.timerCountInSec);
        }, 1000);
        },
        stopTimer(){
            clearInterval(this.interval);
        },
        breakSetTimerClock(sec){
            this.breakTimerClock= formatter(sec)
        },
        breakStartTimer(){
            clearInterval(this.breakInterval);
            clearInterval(this.interval);
           this.breakInterval = setInterval(() => {
                this.breakTimerCountInSec--
                this.breakSetTimerClock(this.breakTimerCountInSec,'positive')
        }, 1000);
        },
        breakStopTimer(){
            clearInterval(this.breakInterval);
        },
        async GetTrackHours(){
            const {data} = await DataService.GetTrackHours();
            this.trackHoursData=data.result
           // console.log("ttttttt",trackHoursData);

        },
        async getBreaksDetails(param){
            const { data } = await DataService.GetBreaksDetails(param)
            this.breakDetailsState = data
        }
    },
   // persist: true,    
})