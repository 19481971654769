<script>
export default {
  data(){
    return{
      
    }
  }
}
</script>
<template>
  <div class="container-fluid">
    <RouterLink to="/Home" class="navbar-brand" checkPrivilege="Yes">
      <img
        src="/Content/images/DefaultImages/company-logo.jpg"
        loading="lazy"
        id="imghdrCompanyLogo"
        class="img-fluid"
        alt=""
      />
    </RouterLink>
    <button
      class="navbar-toggler navbar-toggler-right"
      type="button"
      data-toggle="collapse"
      data-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item m-n-item wizardnew">
          <a
            class="nav-link"
            data-toggle="modal"
            href="#choosewizards"
            title="Wizard"
            ><span class="text-lmenu">@Resources.Wizard</span></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            title="@Resources.FullScreen"
            id="btnFullscreen"
            href="javascript:;"
          >
            <em class="zoom-screen" aria-hidden="true">
              <img
                class="dark-full-screen"
                style="height: 1rem; width: 1rem"   alt=""
                src="/Content/images/iconfinder_fullscreen.png"
              />
              <img
                class="light-full-screen"
                style="height: 1rem; width: 1rem; display: none"   alt=""
                src="/Content/images/iconfinder_fullscreen-light.png"
              />
            </em>
            <span class="text-lmenu">FullScreen</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            id="popChatLink"
            class="nav-link"
            title="@Resources.Communication"
            href="javascript:;"
            ><em class="fa fa-commenting-o actionicon_20"
              ><small
                id="popChatCount"
                class="notification_bell"
                style="display: none"
              ></small></em><span class="bookmark text-lmenu">Communication</span></a
          >
        </li>
        <li class="nav-item">
          <a
            id="popNotificationLink"
            class="nav-link"
            title="@Resources.Notifications"
            href="javascript:;"
            ><em class="fa fa-bell position-relative" style="font-size: 18px"
              ><small
                id="popNotificationCount"
                class="noti-circle noti-green px-1 position-absolute d-none"
                style="left: 20px; top: -4px"
              ></small></em><span class="bookmark text-lmenu ml-1">Notifications</span></a
          >
          <div
            id="divPopNotificationData"
            style="display: none"
            class="position-absolute border themecontrol"
          ></div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link ancbookmark"
            title="@Resources.Bookmark"
            href="javascript:;"
            ><em class="fa fa-star"></em><span class="bookmark text-lmenu">Bookmark</span></a
          >
        </li>
        <li class="nav-item d-none">
          <a class="nav-link" title="@Resources.Socialize" href="javascript:;"
            ><em class="fa fa-comments"></em>
            <span class="socialize">Socialize</span></a
          >
        </li>
        <RouterLink
          to="/Dashboard"
          class="nav-link sidebaricon"
          checkPrivilege="Yes"
        >
          <span>Dashboard</span>
        </RouterLink>
        <li class="nav-item dropdown position-unset">
          <a
            class="nav-link dropdown-toggle sidebaricon position-relative"
            :title="$t('FullMenu')"
            href="javascript:;"
            id="navbarDropdownPortfolio"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <em class="fa fa-th-large i-lmenu"></em><span>FullMenu</span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right full-menu-new overflow-visible"
            id="fullMenuUl"
            aria-labelledby="navbarDropdownPortfolio"
            style="max-height: 1000px"
          >
            <div class="menu-search-panel">
              <input
                type="text"
                name="search"
                value=""
                class="menusearchitem"
                placeholder="Type to Search Menu items"
                autocomplete="off"
              />
              <a
                class="clear-search"
                href="javascript:;"
                title=""
                data-toggle-tooltip="tooltip"
                data-original-title="Clear"
                ><em class="fa fa-times"></em></a>
            </div>
            <div class="navigation-panel w-100">
              <div class="nav-heading" style="display: none">
                <span
                  ><em class="fa fa-home cursor-pointer text-dark pr-2 ancbacktomenu"
                  ></em
                ></span>
                <span><em class="fa fa-angle-right text-dark pr-2"></em></span>
                <span id="spntext"><em class="fa fa-th-list d-none"></em></span>
                <span class="back ml-auto"
                  ><a
                    class="cursor-pointer ancbacktomenu"
                    title=""
                    data-toggle-tooltip="tooltip"
                    data-original-title="Back"
                    ><em class="fa fa-chevron-left"></em>Back</a
                  ></span
                >
              </div>
              <div
                id="scrollbar"
                style="max-height: 350px"
                class="mCustomScrollbar _mCS_1 mCS_no_scrollbar"
              >
                <div
                  id="mCSB_1"
                  class="mCustomScrollBox mCS-dark-thick mCSB_vertical mCSB_inside"
                  style="max-height: 320px"
                  tabindex="0"
                >
                  <div
                    id="mCSB_1_container"
                    class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                    style="position: relative; top: 0; left: 0"
                    dir="ltr"
                  >
                    <ul>
                      <li
                        data-id="5"
                        data-name="Appointment Calendar"
                        id="menuitem1"
                        class="menuitem"
                        data-index="1"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll1' style='max-height:200px;'><li class='itemhover'>External Link for Appointment </li><li class='itemhover'>Appointment Report</li><li class='itemhover'>Consumer List</li><li class='itemhover'>Counter</li><li class='itemhover'>Course</li><li class='itemhover'>Schedule Appointment</li><li class='itemhover'>Appointment Service</li><li class='itemhover'>Token</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-calendar"></em>Appointment Calendar
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="16"
                        data-name="Approval"
                        id="menuitem2"
                        class="menuitem"
                        data-index="2"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll2' style='max-height:200px;'><li class='itemhover'>Approval Chain </li><li class='itemhover'>Approval Group</li><li class='itemhover'>Approval Report</li><li class='itemhover'>Approval Rule</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-check-circle"></em>Approval
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="6"
                        data-url="/Asset/Dashboard?reqfrom=6"
                        data-privilege="Yes"
                        data-name="Asset Management "
                        id="menuitem3"
                        class="menuitem"
                        data-index="3"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/Asset/Dashboard?reqfrom=6"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll3' style='max-height:200px;'><li class='itemhover'>Group List</li><li class='itemhover'>Items Scan Summary</li><li class='itemhover'>Return Products</li><li class='itemhover'>RFID Asset Listing</li><li class='itemhover'>RFID Missing Assets</li><li class='itemhover'>Scanned Items</li><li class='itemhover'>Scanned Items Status</li><li class='itemhover'>Asset Dashboard</li><li class='itemhover'>Manage Product</li><li class='itemhover'>My Products</li><li class='itemhover'>Product Write-Off</li><li class='itemhover'>Product Report</li><li class='itemhover'>Product Assignment</li><li class='itemhover'>Products Container List</li><li class='itemhover'>Product Type</li><li class='itemhover'>New Product Type Request</li><li class='itemhover'>Pending Requests</li><li class='itemhover'>Maintenance Schedule</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-th-list"></em>Asset Management
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="9"
                        data-name="Billing"
                        id="menuitem4"
                        class="menuitem"
                        data-index="4"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll4' style='max-height:200px;'><li class='itemhover'>Automate Flag Processing</li><li class='itemhover'>Billing Rule</li><li class='itemhover'>Billing Services</li><li class='itemhover'>Billing Setup</li><li class='itemhover'>Flag Records</li><li class='itemhover'>Client Report</li><li class='itemhover'>Add New Invoice</li><li class='itemhover'>Import Invoice</li><li class='itemhover'>Invoice</li><li class='itemhover'>Invoice Report</li><li class='itemhover'>Process &amp; Reprocess Charges</li><li class='itemhover'>Rate Schedule</li><li class='itemhover'>Received Invoice</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-wpforms"></em>Billing
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="0"
                        data-name="COMPANY SETUP"
                        id="menuitem5"
                        class="menuitem"
                        data-index="5"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll5' style='max-height:200px;'><li class='itemhover'>Clients</li><li class='itemhover'>Company Directory </li><li class='itemhover'>Company Setup</li><li class='itemhover'>Configuration</li><li class='itemhover'>Context Menu</li><li class='itemhover'>Manage Layout</li><li class='itemhover'>Manage Subscription</li><li class='itemhover'>Manage Tax</li><li class='itemhover'>Manage Tax</li><li class='itemhover'>Resource Approval</li><li class='itemhover'>Roles</li><li class='itemhover'>Search Vendors</li><li class='itemhover'>User</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-cog"></em>COMPANY SETUP
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="4"
                        data-url="/CRM/dashboard?reqfrom=4"
                        data-privilege="Yes"
                        data-name="CRM"
                        id="menuitem6"
                        class="menuitem"
                        data-index="6"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/CRM/dashboard?reqfrom=4"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll6' style='max-height:200px;'><li class='itemhover'>Accounts</li><li class='itemhover'>Brand</li><li class='itemhover'>Campaign </li><li class='itemhover'>Communication</li><li class='itemhover'>Contact</li><li class='itemhover'>Contract</li><li class='itemhover'>CRM Dashboard</li><li class='itemhover'>CRM Report</li><li class='itemhover'>Deal</li><li class='itemhover'>Goods Inward</li><li class='itemhover'>Invoice</li><li class='itemhover'>Product</li><li class='itemhover'>Product Type</li><li class='itemhover'>Lead</li><li class='itemhover'>Price Book</li><li class='itemhover'>Purchase Order</li><li class='itemhover'>Quote</li><li class='itemhover'>Sales Order</li><li class='itemhover'>Stage</li><li class='itemhover'>Vendor</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-handshake-o"></em>CRM
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="13"
                        data-url="/File/Dashboard?reqfrom=13"
                        data-privilege="Yes"
                        data-name="Document Storage"
                        id="menuitem7"
                        class="menuitem"
                        data-index="7"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/File/Dashboard?reqfrom=13"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll7' style='max-height:200px;'><li class='itemhover'>File Dashboard</li><li class='itemhover'>Manage Client Storage</li><li class='itemhover'>Manage User Storage</li><li class='itemhover'>User Storage Report</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-server"></em>Document Storage
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                    </ul>
                    <ul>
                      <li
                        data-id="11"
                        data-url="/Event/Dashboard?reqfrom=11"
                        data-privilege="Yes"
                        data-name="Event Management"
                        id="menuitem8"
                        class="menuitem"
                        data-index="8"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/Event/Dashboard?reqfrom=11"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll8' style='max-height:200px;'><li class='itemhover'>Calendar</li><li class='itemhover'>Google Calendar</li><li class='itemhover'>Dashboard</li><li class='itemhover'>Event Management</li><li class='itemhover'>Manage Venue</li><li class='itemhover'>User Availability </li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-calendar-check-o"></em>Event Management
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="2"
                        data-url="/Expenses/Dashboard?reqfrom=2"
                        data-privilege="Yes"
                        data-name="Expense "
                        id="menuitem9"
                        class="menuitem"
                        data-index="9"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/Expenses/Dashboard?reqfrom=2"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll9' style='max-height:200px;'><li class='itemhover'>Add Expense</li><li class='itemhover'>Bulk Upload Expense</li><li class='itemhover'>Expense Approval</li><li class='itemhover'>Expense Report</li><li class='itemhover'>Expenses</li><li class='itemhover'>Expense Dashboard</li><li class='itemhover'>Project</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-usd"></em>Expense
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="10"
                        data-name="General"
                        id="menuitem10"
                        class="menuitem"
                        data-index="10"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll10' style='max-height:200px;'><li class='itemhover'>Add a Report</li><li class='itemhover'>Auto Responder</li><li class='itemhover'>Custom Fields</li><li class='itemhover'>Custom Reports</li><li class='itemhover'>External Link</li><li class='itemhover'>Passport Information</li><li class='itemhover'>Manage Tags</li><li class='itemhover'>Organization Chart</li><li class='itemhover'>Provisional Rights</li><li class='itemhover'>Rule Engine</li><li class='itemhover'>Storage</li><li class='itemhover'>Tags</li><li class='itemhover'>Working Day</li><li class='itemhover'>Working Week</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-file"></em>General
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="7"
                        data-name="Hiring"
                        id="menuitem11"
                        class="menuitem"
                        data-index="11"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll11' style='max-height:200px;'><li class='itemhover'>Associated Vendors</li><li class='itemhover'>Candidate List</li><li class='itemhover'>Consultant List</li><li class='itemhover'>Group List</li><li class='itemhover'>Job Board Layout Settings</li><li class='itemhover'>Job List</li><li class='itemhover'>Job Post Report</li><li class='itemhover'>Job Requisition</li><li class='itemhover'>Manage Candidate</li><li class='itemhover'>Manage Job</li><li class='itemhover'>Offboard Employee List</li><li class='itemhover'>Policy</li><li class='itemhover'>Resignation</li><li class='itemhover'>Service</li><li class='itemhover'>Talent Pool List</li><li class='itemhover'>Vendor Listing</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-handshake-o"></em>Hiring
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="33"
                        data-url="/HR/Dashboard?reqfrom=33"
                        data-privilege="Yes"
                        data-name="Human Resources"
                        id="menuitem12"
                        class="menuitem"
                        data-index="12"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/HR/Dashboard?reqfrom=33"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll12' style='max-height:200px;'><li class='itemhover'>Attendance</li><li class='itemhover'>Attendance Approval</li><li class='itemhover'>Candidate List</li><li class='itemhover'>Check-In Flag List</li><li class='itemhover'>Consultant List</li><li class='itemhover'>Group List</li><li class='itemhover'>HR Dashboard</li><li class='itemhover'>HR Report</li><li class='itemhover'>Induction Document List</li><li class='itemhover'>Leave</li><li class='itemhover'>Leave Approval</li><li class='itemhover'>Leave Type- Listing</li><li class='itemhover'>Manage Candidate</li><li class='itemhover'>Manage Check-In</li><li class='itemhover'>Talent Pool List</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-exclamation-triangle"></em>Human
                          Resources
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="17"
                        data-name="Integration"
                        id="menuitem13"
                        class="menuitem"
                        data-index="13"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll13' style='max-height:200px;'><li class='itemhover'>Basecamp</li><li class='itemhover'>Cloud Storage</li><li class='itemhover'>Connector</li><li class='itemhover'>Email</li><li class='itemhover'>FreshBooks</li><li class='itemhover'>GPS Tracking</li><li class='itemhover'>IVR</li><li class='itemhover'>Microsoft Project Plan</li><li class='itemhover'>QuickBooks</li><li class='itemhover'>Salesforce</li><li class='itemhover'>Trello</li><li class='itemhover'>Twilio</li><li class='itemhover'>Unified Setup</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-clone"></em>Integration
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                    </ul>
                    <ul>
                      <li
                        data-id="14"
                        data-name="Performance Review"
                        id="menuitem14"
                        class="menuitem"
                        data-index="14"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll14' style='max-height:200px;'><li class='itemhover'>Performance Review</li><li class='itemhover'>Performance Review Report</li><li class='itemhover'>Provide Feedback</li><li class='itemhover'>Provide Review</li><li class='itemhover'>Review</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-file-text"></em>Performance Review
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="1"
                        data-url="/Project/Dashboard?reqfrom=1"
                        data-privilege="Yes"
                        data-name="Project Management"
                        id="menuitem15"
                        class="menuitem"
                        data-index="15"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/Project/Dashboard?reqfrom=1"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll15' style='max-height:200px;'><li class='itemhover'>Dashboard</li><li class='itemhover'>Global Task</li><li class='itemhover'>Project</li><li class='itemhover'>Project Report</li><li class='itemhover'>Project Request</li><li class='itemhover'>Quotes</li><li class='itemhover'>Requested Time List</li><li class='itemhover'>Resource Scheduling </li><li class='itemhover'>Project Road Map</li><li class='itemhover'>Task</li><li class='itemhover'>To Do List</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-folder-open"></em>Project Management
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="12"
                        data-url="/Ticketing/Dashboard?reqfrom=12"
                        data-privilege="Yes"
                        data-name="Ticketing"
                        id="menuitem16"
                        class="menuitem"
                        data-index="16"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="/Ticketing/Dashboard?reqfrom=12"
                          checkprivilege="Yes"
                          data-content="<ul class='itemscroll w-100' id='itemscroll16' style='max-height:200px;'><li class='itemhover'>Ticketing Dashboard</li><li class='itemhover'>Ticketing</li><li class='itemhover'>Add Ticket</li><li class='itemhover'>Unassigned Tickets</li><li class='itemhover'>Ticket Feedback</li><li class='itemhover'>Canned Reply</li><li class='itemhover'>Ticket Layout Settings</li><li class='itemhover'>Ticket Report</li><li class='itemhover'>Manage Service Board</li><li class='itemhover'>Ticket Group</li><li class='itemhover'>SLA</li><li class='itemhover'>Add ticket Group</li><li class='itemhover'>Knowledge Base</li><li class='itemhover'>Manage Category</li><li class='itemhover'>My Articles</li><li class='itemhover'>Product</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer"
                        >
                          <em class="fa fa-ticket"></em>Ticketing
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="3"
                        data-name="Time Tracking"
                        id="menuitem17"
                        class="menuitem"
                        data-index="17"
                        data-show-pop="yes"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll17' style='max-height:200px;'><li class='itemhover'>Audit Rule</li><li class='itemhover'>Bulk Time Entry</li><li class='itemhover'>Calendar</li><li class='itemhover'>Flag Track Entry</li><li class='itemhover'>Project Report</li><li class='itemhover'>Time Report</li><li class='itemhover'>Time Approval</li><li class='itemhover'>Timesheet</li><li class='itemhover'>Time Track</li><li class='itemhover'>Work Diary</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-clock-o"></em>Time Tracking
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                      <li
                        data-id="15"
                        data-name="Training"
                        id="menuitem18"
                        class="menuitem"
                        data-index="18"
                        data-show-pop="no"
                      >
                        <a
                          data-toggle="popover"
                          data-placement="right"
                          href="javascript:;"
                          data-content="<ul class='itemscroll w-100' id='itemscroll18' style='max-height:200px;'><li class='itemhover'>Training</li><li class='itemhover'>Training Report</li></ul>"
                          data-html="true"
                          class="mainmenu menuitemsover cursor-pointer menudashboard"
                        >
                          <em class="fa fa-clipboard"></em>Training
                        </a>
                        <span class="fa fa-angle-right gotosub"></span>
                      </li>
                    </ul>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_15"
                        data-p-id="15"
                        data-p-name="Training"
                      >
                        <li class="menuitem" menucount="0" count="1">
                          <a
                            href="/Training/Index?reqfrom=15"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Training"
                            ><em class="fa fa-circle"></em>Training</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_15"
                        data-p-id="15"
                        data-p-name="Training"
                      >
                        <li class="menuitem" menucount="0" count="1">
                          <a
                            href="/TrainingReport/Index?reqfrom=15"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Training Report"
                            ><em class="fa fa-circle"></em>Training Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_15"
                        data-p-id="15"
                        data-p-name="Training"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_16"
                        data-p-id="16"
                        data-p-name="Approval"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/ApprovalChain/Index?reqfrom=16"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Approval Chain "
                            ><em class="fa fa-circle"></em>Approval Chain
                          </a>
                        </li>
                        <li class="menuitem" menucount="1" count="2">
                          <a
                            href="/ApprovalGroup/Index?reqfrom=16"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Approval Group"
                            ><em class="fa fa-circle"></em>Approval Group</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_16"
                        data-p-id="16"
                        data-p-name="Approval"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/ApprovalReport/Index?reqfrom=16"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Approval Report"
                            ><em class="fa fa-circle"></em>Approval Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_16"
                        data-p-id="16"
                        data-p-name="Approval"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/ApprovalRule/Index?reqfrom=16"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Approval Rule"
                            ><em class="fa fa-circle"></em>Approval Rule</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_16"
                        data-p-id="16"
                        data-p-name="Approval"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_13"
                        data-p-id="13"
                        data-p-name="Document Storage"
                      >
                        <li class="menuitem" menucount="1" count="2">
                          <a
                            href="/UserStorage/ManageClient?reqfrom=13"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Client Storage"
                            ><em class="fa fa-circle"></em>Manage Client
                            Storage</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_13"
                        data-p-id="13"
                        data-p-name="Document Storage"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/UserStorage/Manage?reqfrom=13"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage User Storage"
                            ><em class="fa fa-circle"></em>Manage User Storage</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_13"
                        data-p-id="13"
                        data-p-name="Document Storage"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/UserStorageReport/Index?reqfrom=13"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="User Storage Report"
                            ><em class="fa fa-circle"></em>User Storage Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_13"
                        data-p-id="13"
                        data-p-name="Document Storage"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_14"
                        data-p-id="14"
                        data-p-name="Performance Review"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/Review/Main?reqfrom=14"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Performance Review"
                            ><em class="fa fa-circle"></em>Performance Review</a
                          >
                        </li>
                        <li class="menuitem" menucount="1" count="2">
                          <a
                            href="/PerformanceReviewReport/Index?reqfrom=14"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Performance Review Report"
                            ><em class="fa fa-circle"></em>Performance Review
                            Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_14"
                        data-p-id="14"
                        data-p-name="Performance Review"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/Feedback/UserFeedbackListing?reqfrom=14"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Provide Feedback"
                            ><em class="fa fa-circle"></em>Provide Feedback</a
                          >
                        </li>
                        <li class="menuitem" menucount="1" count="2">
                          <a
                            href="/Review/ReviewListingForReviewer?reqfrom=14"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Provide Review"
                            ><em class="fa fa-circle"></em>Provide Review</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_14"
                        data-p-id="14"
                        data-p-name="Performance Review"
                      >
                        <li class="menuitem" menucount="1" count="1">
                          <a
                            href="/Review/Index?reqfrom=14"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Review"
                            ><em class="fa fa-circle"></em>Review</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_14"
                        data-p-id="14"
                        data-p-name="Performance Review"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_11"
                        data-p-id="11"
                        data-p-name="Event Management"
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Calendar/Calendar?reqfrom=11"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Calendar"
                            ><em class="fa fa-circle"></em>Calendar</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/CalSource/Manage?reqfrom=11"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Google Calendar"
                            ><em class="fa fa-circle"></em>Google Calendar</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="4">
                          <a
                            href="/Event/Index?reqfrom=11"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Event Management"
                            ><em class="fa fa-circle"></em>Event Management</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_11"
                        data-p-id="11"
                        data-p-name="Event Management"
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Event/ManageLocation?reqfrom=11"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Venue"
                            ><em class="fa fa-circle"></em>Manage Venue</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/UserAvailability/Index?reqfrom=11"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="User Availability "
                            ><em class="fa fa-circle"></em>User Availability
                          </a>
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_11"
                        data-p-id="11"
                        data-p-name="Event Management"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_2"
                        data-p-id="2"
                        data-p-name="Expense "
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Expenses/Add?reqfrom=2"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Add Expense"
                            ><em class="fa fa-circle"></em>{{$t('AddExpenses')}}</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Expenses/Import?reqfrom=2"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Bulk Upload Expense"
                            ><em class="fa fa-circle"></em>{{$t('BulkUploadExpense')}}</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="3">
                          <a
                            href="/ExpenseApproval/Index?reqfrom=2"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Expense Approval"
                            ><em class="fa fa-circle"></em>{{$t('ExpenseApproval')}}</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_2"
                        data-p-id="2"
                        data-p-name="Expense "
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/ExpenseReport/Index?reqfrom=2"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Expense Report"
                            ><em class="fa fa-circle"></em>{{$t('ExpenseReport')}}</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Expenses/Index?reqfrom=2"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Expenses"
                            ><em class="fa fa-circle"></em>{{ $t('Expenses') }}</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_2"
                        data-p-id="2"
                        data-p-name="Expense "
                      >
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Project/Index?reqfrom=2"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Project"
                            ><em class="fa fa-circle"></em>{{$t('Header_Projects')}}</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_2"
                        data-p-id="2"
                        data-p-name="Expense "
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_5"
                        data-p-id="5"
                        data-p-name="Appointment Calendar"
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Appointment/GlobalURL?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="External Link for Appointment "
                            ><em class="fa fa-circle"></em>External Link for
                            Appointment
                          </a>
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/AppointmentCalendarReport/Index?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Appointment Report"
                            ><em class="fa fa-circle"></em>Appointment Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="3">
                          <a
                            href="/Consumer/List?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Consumer List"
                            ><em class="fa fa-circle"></em>Consumer List</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_5"
                        data-p-id="5"
                        data-p-name="Appointment Calendar"
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Counter/index?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Counter"
                            ><em class="fa fa-circle"></em>Counter</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Course/Index?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Course"
                            ><em class="fa fa-circle"></em>Course</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="3">
                          <a
                            href="/Service/Index?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Schedule Appointment"
                            ><em class="fa fa-circle"></em>Schedule Appointment</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_5"
                        data-p-id="5"
                        data-p-name="Appointment Calendar"
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/ServiceMaster/List?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Appointment Service"
                            ><em class="fa fa-circle"></em>Appointment Service</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/CounterToken/index?reqfrom=5"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Token"
                            ><em class="fa fa-circle"></em>Token</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_5"
                        data-p-id="5"
                        data-p-name="Appointment Calendar"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_3"
                        data-p-id="3"
                        data-p-name="Time Tracking"
                      >
                        <li class="menuitem" menucount="3" count="1">
                          <a
                            href="/Audit/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Audit Rule"
                            ><em class="fa fa-circle"></em>Audit Rule</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="2">
                          <a
                            href="/Timer/BulkTimeEntry?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Bulk Time Entry"
                            ><em class="fa fa-circle"></em>Bulk Time Entry</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="3">
                          <a
                            href="/Calendar/Calendar?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Calendar"
                            ><em class="fa fa-circle"></em>Calendar</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="4">
                          <a
                            href="/Timer/TrackEntryFlag?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Flag Track Entry"
                            ><em class="fa fa-circle"></em>Flag Track Entry</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_3"
                        data-p-id="3"
                        data-p-name="Time Tracking"
                      >
                        <li class="menuitem" menucount="3" count="1">
                          <a
                            href="/BIReport/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Project Report"
                            ><em class="fa fa-circle"></em>Project Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="2">
                          <a
                            href="/BIReport/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Time Report"
                            ><em class="fa fa-circle"></em>Time Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="3">
                          <a
                            href="/TimeApproval/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Time Approval"
                            ><em class="fa fa-circle"></em>Time Approval</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_3"
                        data-p-id="3"
                        data-p-name="Time Tracking"
                      >
                        <li class="menuitem" menucount="3" count="1">
                          <a
                            href="/Timesheet/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Timesheet"
                            ><em class="fa fa-circle"></em>Timesheet</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="2">
                          <a
                            href="/Timer/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Time Track"
                            ><em class="fa fa-circle"></em>Time Track</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="3">
                          <a
                            href="/WorkDiary/Index?reqfrom=3"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Work Diary"
                            ><em class="fa fa-circle"></em>Work Diary</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_3"
                        data-p-id="3"
                        data-p-name="Time Tracking"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_1"
                        data-p-id="1"
                        data-p-name="Project Management"
                      >
                        <li class="menuitem" menucount="3" count="2">
                          <a
                            href="/GlobalTask/Index?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Global Task"
                            ><em class="fa fa-circle"></em>Global Task</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="3">
                          <a
                            href="/Project/Index?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Project"
                            ><em class="fa fa-circle"></em>Project</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="4">
                          <a
                            href="/BIReport/Index?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Project Report"
                            ><em class="fa fa-circle"></em>Project Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_1"
                        data-p-id="1"
                        data-p-name="Project Management"
                      >
                        <li class="menuitem" menucount="3" count="1">
                          <a
                            href="/ProjectRequest/Index?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Project Request"
                            ><em class="fa fa-circle"></em>Project Request</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="2">
                          <a
                            href="/Quotation/Index?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Quotes"
                            ><em class="fa fa-circle"></em>Quotes</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="3">
                          <a
                            href="/Project/RequestTimeList?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Requested Time List"
                            ><em class="fa fa-circle"></em>Requested Time List</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="4">
                          <a
                            href="/ResourceScheduling/Create?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Resource Scheduling "
                            ><em class="fa fa-circle"></em>Resource Scheduling
                          </a>
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_1"
                        data-p-id="1"
                        data-p-name="Project Management"
                      >
                        <li class="menuitem" menucount="3" count="1">
                          <a
                            href="/RoadMap/View?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Project Road Map"
                            ><em class="fa fa-circle"></em>Project Road Map</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="2">
                          <a
                            href="/Task/Index?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Task"
                            ><em class="fa fa-circle"></em>Task</a
                          >
                        </li>
                        <li class="menuitem" menucount="3" count="3">
                          <a
                            href="/Project/ToDoList?reqfrom=1"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="To Do List"
                            ><em class="fa fa-circle"></em>To Do List</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_1"
                        data-p-id="1"
                        data-p-name="Project Management"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_9"
                        data-p-id="9"
                        data-p-name="Billing"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/ClientFlag/Index?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Automate Flag Processing"
                            ><em class="fa fa-circle"></em>Automate Flag
                            Processing</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/BillingRule/RuleListing?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Billing Rule"
                            ><em class="fa fa-circle"></em>Billing Rule</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/BillingService/Index?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Billing Services"
                            ><em class="fa fa-circle"></em>Billing Services</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/BillingRule/ImportFormatListing?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Billing Setup"
                            ><em class="fa fa-circle"></em>Billing Setup</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="5">
                          <a
                            href="/ClientInvoice/CatchAll?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Flag Records"
                            ><em class="fa fa-circle"></em>Flag Records</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_9"
                        data-p-id="9"
                        data-p-name="Billing"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/ClientReport/Index?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Client Report"
                            ><em class="fa fa-circle"></em>Client Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/ClientBill/Create?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Add New Invoice"
                            ><em class="fa fa-circle"></em>Add New Invoice</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/ClientInvoice/ImportFileList?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Import Invoice"
                            ><em class="fa fa-circle"></em>Import Invoice</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/ClientBill/Index?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Invoice"
                            ><em class="fa fa-circle"></em>Invoice</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_9"
                        data-p-id="9"
                        data-p-name="Billing"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/InvoiceReport/Index?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Invoice Report"
                            ><em class="fa fa-circle"></em>Invoice Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/BillingRule/ReprocessCharge?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Process &amp; Reprocess Charges"
                            ><em class="fa fa-circle"></em>Process &amp; Reprocess
                            Charges</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/RateSchedule/Index?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Rate Schedule"
                            ><em class="fa fa-circle"></em>Rate Schedule</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/ClientBill/RecievedInvoice?reqfrom=9"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Received Invoice"
                            ><em class="fa fa-circle"></em>Received Invoice</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_9"
                        data-p-id="9"
                        data-p-name="Billing"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_0"
                        data-p-id="0"
                        data-p-name="COMPANY SETUP"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Client/Index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Clients"
                            ><em class="fa fa-circle"></em>Clients</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/User/DirectoryListing?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Company Directory "
                            ><em class="fa fa-circle"></em>Company Directory
                          </a>
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/AnnouncementMessage/Index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Company Setup"
                            ><em class="fa fa-circle"></em>Company Setup</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/Configuration/index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Configuration"
                            ><em class="fa fa-circle"></em>Configuration</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="5">
                          <a
                            href="/CompanyAdmin/ViewContextMenu?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Context Menu"
                            ><em class="fa fa-circle"></em>Context Menu</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_0"
                        data-p-id="0"
                        data-p-name="COMPANY SETUP"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Home/ManageLayout?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Layout"
                            ><em class="fa fa-circle"></em>Manage Layout</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Billing/Index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Subscription"
                            ><em class="fa fa-circle"></em>Manage Subscription</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/Tax/Index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Tax"
                            ><em class="fa fa-circle"></em>Manage Tax</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/Home/ManageTax?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Tax"
                            ><em class="fa fa-circle"></em>Manage Tax</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_0"
                        data-p-id="0"
                        data-p-name="COMPANY SETUP"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/EmployeeSharing/RequestRaisedForMe?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Resource Approval"
                            ><em class="fa fa-circle"></em>Resource Approval</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Roles/Index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Roles"
                            ><em class="fa fa-circle"></em>Roles</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/vendoruser/searchvendors?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Search Vendors"
                            ><em class="fa fa-circle"></em>Search Vendors</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/User/Index?reqfrom=0"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="User"
                            ><em class="fa fa-circle"></em>User</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_0"
                        data-p-id="0"
                        data-p-name="COMPANY SETUP"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_17"
                        data-p-id="17"
                        data-p-name="Integration"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Integration/BaseCamp?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Basecamp"
                            ><em class="fa fa-circle"></em>Basecamp</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Integration/GoogleDrive?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Cloud Storage"
                            ><em class="fa fa-circle"></em>Cloud Storage</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/Integration/Connector?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Connector"
                            ><em class="fa fa-circle"></em>Connector</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/Outlook/View?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Email"
                            ><em class="fa fa-circle"></em>Email</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="5">
                          <a
                            href="/Integration/FreshBooks?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="FreshBooks"
                            ><em class="fa fa-circle"></em>FreshBooks</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_17"
                        data-p-id="17"
                        data-p-name="Integration"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Integration/Geotab?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="GPS Tracking"
                            ><em class="fa fa-circle"></em>GPS Tracking</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Communication/IVR?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="IVR"
                            ><em class="fa fa-circle"></em>IVR</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/Integration/MicrosoftProjectPlan?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Microsoft Project Plan"
                            ><em class="fa fa-circle"></em>Microsoft Project
                            Plan</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/Integration/QuickBooks?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="QuickBooks"
                            ><em class="fa fa-circle"></em>QuickBooks</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_17"
                        data-p-id="17"
                        data-p-name="Integration"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Integration/SalesForce?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Salesforce"
                            ><em class="fa fa-circle"></em>Salesforce</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Trello/Add?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Trello"
                            ><em class="fa fa-circle"></em>Trello</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/Integration/Twilio?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Twilio"
                            ><em class="fa fa-circle"></em>Twilio</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/UnifiedCommunication/Setup?reqfrom=17"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Unified Setup"
                            ><em class="fa fa-circle"></em>Unified Setup</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_17"
                        data-p-id="17"
                        data-p-name="Integration"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_10"
                        data-p-id="10"
                        data-p-name="General"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Report/Add?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Add a Report"
                            ><em class="fa fa-circle"></em>Add a Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/AutoResponder/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Auto Responder"
                            ><em class="fa fa-circle"></em>Auto Responder</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/CustomField/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Custom Fields"
                            ><em class="fa fa-circle"></em>Custom Fields</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/Report/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Custom Reports"
                            ><em class="fa fa-circle"></em>Custom Reports</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="5">
                          <a
                            href="/CompanyAdmin/ExternalLinkList?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="External Link"
                            ><em class="fa fa-circle"></em>External Link</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_10"
                        data-p-id="10"
                        data-p-name="General"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Immigration/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Passport Information"
                            ><em class="fa fa-circle"></em>Passport Information</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Tag/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Tags"
                            ><em class="fa fa-circle"></em>Manage Tags</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/OrganizationChart/OrgChart?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Organization Chart"
                            ><em class="fa fa-circle"></em>Organization Chart</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/ProvisionalRight/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Provisional Rights"
                            ><em class="fa fa-circle"></em>Provisional Rights</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="5">
                          <a
                            href="/RuleEngine/Index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Rule Engine"
                            ><em class="fa fa-circle"></em>Rule Engine</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_10"
                        data-p-id="10"
                        data-p-name="General"
                      >
                        <li class="menuitem" menucount="4" count="1">
                          <a
                            href="/Storage/index?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Storage"
                            ><em class="fa fa-circle"></em>Storage</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="2">
                          <a
                            href="/Tag/TagData?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Tags"
                            ><em class="fa fa-circle"></em>Tags</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="3">
                          <a
                            href="/WorkDay/Manage?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Working Day"
                            ><em class="fa fa-circle"></em>Working Day</a
                          >
                        </li>
                        <li class="menuitem" menucount="4" count="4">
                          <a
                            href="/Week/manage?reqfrom=10"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Working Week"
                            ><em class="fa fa-circle"></em>Working Week</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_10"
                        data-p-id="10"
                        data-p-name="General"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_33"
                        data-p-id="33"
                        data-p-name="Human Resources"
                      >
                        <li class="menuitem" menucount="5" count="1">
                          <a
                            href="/Attendance/Index?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Attendance"
                            ><em class="fa fa-circle"></em>Attendance</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="2">
                          <a
                            href="/AttendanceApproval/Index?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Attendance Approval"
                            ><em class="fa fa-circle"></em>Attendance Approval</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="3">
                          <a
                            href="/HR/CandidateList?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Candidate List"
                            ><em class="fa fa-circle"></em>Candidate List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="4">
                          <a
                            href="/Attendance/CheckinFlagList?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Check-In Flag List"
                            ><em class="fa fa-circle"></em>Check-In Flag List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="5">
                          <a
                            href="/HR/ConsultantList?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Consultant List"
                            ><em class="fa fa-circle"></em>Consultant List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="6">
                          <a
                            href="/HR/GroupListing?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Group List"
                            ><em class="fa fa-circle"></em>Group List</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_33"
                        data-p-id="33"
                        data-p-name="Human Resources"
                      >
                        <li class="menuitem" menucount="5" count="2">
                          <a
                            href="/HRReport/Index?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="HR Report"
                            ><em class="fa fa-circle"></em>HR Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="3">
                          <a
                            href="/HR/InductionDocumentList?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Induction Document List"
                            ><em class="fa fa-circle"></em>Induction Document
                            List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="4">
                          <a
                            href="/HR/LeaveList?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Leave"
                            ><em class="fa fa-circle"></em>Leave</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="5">
                          <a
                            href="/LeaveApproval/Index?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Leave Approval"
                            ><em class="fa fa-circle"></em>Leave Approval</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_33"
                        data-p-id="33"
                        data-p-name="Human Resources"
                      >
                        <li class="menuitem" menucount="5" count="1">
                          <a
                            href="/HR/LeaveTypeListing?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Leave Type- Listing"
                            ><em class="fa fa-circle"></em>Leave Type- Listing</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="2">
                          <a
                            href="/HR/ManageCandidate?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Candidate"
                            ><em class="fa fa-circle"></em>Manage Candidate</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="3">
                          <a
                            href="/Attendance/UserTrack?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Check-In"
                            ><em class="fa fa-circle"></em>Manage Check-In</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="4">
                          <a
                            href="/HR/TalentPoolList?reqfrom=33"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Talent Pool List"
                            ><em class="fa fa-circle"></em>Talent Pool List</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_7"
                        data-p-id="7"
                        data-p-name="Hiring"
                      >
                        <li class="menuitem" menucount="5" count="1">
                          <a
                            href="/vendoruser/associatedvendors?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Associated Vendors"
                            ><em class="fa fa-circle"></em>Associated Vendors</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="2">
                          <a
                            href="/HR/CandidateList?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Candidate List"
                            ><em class="fa fa-circle"></em>Candidate List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="3">
                          <a
                            href="/HR/ConsultantList?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Consultant List"
                            ><em class="fa fa-circle"></em>Consultant List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="4">
                          <a
                            href="/HR/GroupListing?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Group List"
                            ><em class="fa fa-circle"></em>Group List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="5">
                          <a
                            href="/JobPost/ManageLayout?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Job Board Layout Settings"
                            ><em class="fa fa-circle"></em>Job Board Layout
                            Settings</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="6">
                          <a
                            href="/JobPost/JobListing?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Job List"
                            ><em class="fa fa-circle"></em>Job List</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_7"
                        data-p-id="7"
                        data-p-name="Hiring"
                      >
                        <li class="menuitem" menucount="5" count="1">
                          <a
                            href="/JobPostReport/Index?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Job Post Report"
                            ><em class="fa fa-circle"></em>Job Post Report</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="2">
                          <a
                            href="/JobPost/RequisitionList?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Job Requisition"
                            ><em class="fa fa-circle"></em>Job Requisition</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="3">
                          <a
                            href="/HR/ManageCandidate?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Candidate"
                            ><em class="fa fa-circle"></em>Manage Candidate</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="4">
                          <a
                            href="/JobPost/ManageJob?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Job"
                            ><em class="fa fa-circle"></em>Manage Job</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="5">
                          <a
                            href="/ExitPolicy/EmployeeExitListing?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Offboard Employee List"
                            ><em class="fa fa-circle"></em>Offboard Employee
                            List</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_7"
                        data-p-id="7"
                        data-p-name="Hiring"
                      >
                        <li class="menuitem" menucount="5" count="1">
                          <a
                            href="/Policy/policylisting?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Policy"
                            ><em class="fa fa-circle"></em>Policy</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="2">
                          <a
                            href="/ExitPolicy/EmployeeResignationList?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Resignation"
                            ><em class="fa fa-circle"></em>Resignation</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="3">
                          <a
                            href="/vendoruser/ServicesListing?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Service"
                            ><em class="fa fa-circle"></em>Service</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="4">
                          <a
                            href="/HR/TalentPoolList?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Talent Pool List"
                            ><em class="fa fa-circle"></em>Talent Pool List</a
                          >
                        </li>
                        <li class="menuitem" menucount="5" count="5">
                          <a
                            href="/vendoruser/VendorListing?reqfrom=7"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Vendor Listing"
                            ><em class="fa fa-circle"></em>Vendor Listing</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_7"
                        data-p-id="7"
                        data-p-name="Hiring"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      ></ul>
                    </div>
                    <h4 class="hdrGroupName hdrGroupName_12 d-none">
                      Ticketing
                    </h4>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucounth="3" count="1">
                          <a
                            href="/Ticketing/Index?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Ticketing"
                            ><em class="fa fa-circle"></em>Ticketing</a
                          >
                        </li>
                        <li class="menuitem" menucountg="3" count="2">
                          <a
                            href="/Ticketing/add?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Add Ticket"
                            ><em class="fa fa-circle"></em>Add Ticket</a
                          >
                        </li>
                        <li class="menuitem" menucountg="3" count="3">
                          <a
                            href="/Ticketing/Unassigned?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Unassigned Tickets"
                            ><em class="fa fa-circle"></em>Unassigned Tickets</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="3" count="1">
                          <a
                            href="/Ticketing/Feedback?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Ticket Feedback"
                            ><em class="fa fa-circle"></em>Ticket Feedback</a
                          >
                        </li>
                        <li class="menuitem" menucountg="3" count="2">
                          <a
                            href="/ReplyTemplate/Index?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Canned Reply"
                            ><em class="fa fa-circle"></em>Canned Reply</a
                          >
                        </li>
                        <li class="menuitem" menucountg="3" count="3">
                          <a
                            href="/Ticketing/ManageLayout?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Ticket Layout Settings"
                            ><em class="fa fa-circle"></em>Ticket Layout
                            Settings</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="2" count="1">
                          <a
                            href="/TicketReport/Index?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Ticket Report"
                            ><em class="fa fa-circle"></em>Ticket Report</a
                          >
                        </li>
                      </ul>
                    </div>
                    <h4 class="hdrGroupName hdrGroupName_12 d-none">
                      Service Board
                    </h4>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucounth="1" count="1">
                          <a
                            href="/Ticketing/Chain?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Service Board"
                            ><em class="fa fa-circle"></em>Manage Service Board</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Ticketing/Group?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Ticket Group"
                            ><em class="fa fa-circle"></em>Ticket Group</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Ticketing/Policy?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="SLA"
                            ><em class="fa fa-circle"></em>SLA</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Ticketing/AddGroup?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Add ticket Group"
                            ><em class="fa fa-circle"></em>Add ticket Group</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      ></ul>
                    </div>
                    <h4 class="hdrGroupName hdrGroupName_12 d-none">
                      Knowledge Base
                    </h4>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucounth="1" count="1">
                          <a
                            href="/KnowledgeBase/Index?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Knowledge Base"
                            ><em class="fa fa-circle"></em>Knowledge Base</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/KnowledgeBase/ManageCategory?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Category"
                            ><em class="fa fa-circle"></em>Manage Category</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/KnowledgeBase/ManageArticle?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="My Articles"
                            ><em class="fa fa-circle"></em>My Articles</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Product/Index?reqfrom=12"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product"
                            ><em class="fa fa-circle"></em>Product</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_12"
                        data-p-id="12"
                        data-p-name="Ticketing"
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Asset/GroupListing?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Group List"
                            ><em class="fa fa-circle"></em>Group List</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Asset/ItemsScanSummary?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Items Scan Summary"
                            ><em class="fa fa-circle"></em>Items Scan Summary</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Asset/ReturnItems?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Return Products"
                            ><em class="fa fa-circle"></em>Return Products</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Asset/rfidassetlisting?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="RFID Asset Listing"
                            ><em class="fa fa-circle"></em>RFID Asset Listing</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Asset/RFIDMissingAssets?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="RFID Missing Assets"
                            ><em class="fa fa-circle"></em>RFID Missing Assets</a
                          >
                        </li>
                        <li class="menuitem" menucount="2" count="2">
                          <a
                            href="/Asset/ScannedItems?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Scanned Items"
                            ><em class="fa fa-circle"></em>Scanned Items</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucount="2" count="1">
                          <a
                            href="/Asset/RFIDMyDeviceList?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Scanned Items Status"
                            ><em class="fa fa-circle"></em>Scanned Items Status</a
                          >
                        </li>
                      </ul>
                    </div>
                    <h4 class="hdrGroupName hdrGroupName_6 d-none">
                      Item Management
                    </h4>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucounth="2" count="1">
                          <a
                            href="/AssetCatalog/Index?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Manage Product"
                            ><em class="fa fa-circle"></em>Manage Product</a
                          >
                        </li>
                        <li class="menuitem" menucountg="2" count="2">
                          <a
                            href="/Asset/MyItems?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="My Products"
                            ><em class="fa fa-circle"></em>My Products</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucountg="2" count="1">
                          <a
                            href="/AssetCatalog/ItemWriteOffList?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product Write-Off"
                            ><em class="fa fa-circle"></em>Product Write-Off</a
                          >
                        </li>
                        <li class="menuitem" menucountg="2" count="2">
                          <a
                            href="/AssetReport/Index?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product Report"
                            ><em class="fa fa-circle"></em>Product Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      ></ul>
                    </div>
                    <h4 class="hdrGroupName hdrGroupName_6 d-none">
                      Item Assignment
                    </h4>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucounth="1" count="1">
                          <a
                            href="/AssetAssignment/Index?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product Assignment"
                            ><em class="fa fa-circle"></em>Product Assignment</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Asset/AssetGrouping?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Products Container List"
                            ><em class="fa fa-circle"></em>Products Container
                            List</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Asset/Index?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product Type"
                            ><em class="fa fa-circle"></em>Product Type</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      ></ul>
                    </div>
                    <h4 class="hdrGroupName hdrGroupName_6 d-none">Requests</h4>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucounth="1" count="1">
                          <a
                            href="/AssetRequisition/NewAssetRequisitionListing?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="New Product Type Request"
                            ><em class="fa fa-circle"></em>New Product Type
                            Request</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/Asset/PendingRequest?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Pending Requests"
                            ><em class="fa fa-circle"></em>Pending Requests</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      >
                        <li class="menuitem" menucountg="1" count="1">
                          <a
                            href="/AssetCatalog/ScheduleUpdate?reqfrom=6"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Maintenance Schedule"
                            ><em class="fa fa-circle"></em>Maintenance Schedule</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_6"
                        data-p-id="6"
                        data-p-name="Asset Management "
                      ></ul>
                    </div>
                    <div class="w-100 float-left">
                      <ul
                        class="submenu d-none clschild_4"
                        data-p-id="4"
                        data-p-name="CRM"
                      >
                        <li class="menuitem" menucount="6" count="1">
                          <a
                            href="/CRM/Accounts?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Accounts"
                            ><em class="fa fa-circle"></em>Accounts</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="2">
                          <a
                            href="/Brand/BrandListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Brand"
                            ><em class="fa fa-circle"></em>Brand</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="3">
                          <a
                            href="/CRM/CampaignListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Campaign "
                            ><em class="fa fa-circle"></em>Campaign
                          </a>
                        </li>
                        <li class="menuitem" menucount="6" count="4">
                          <a
                            href="/CommunicationModes/Index?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Communication"
                            ><em class="fa fa-circle"></em>Communication</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="5">
                          <a
                            href="/CRM/ContactListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Contact"
                            ><em class="fa fa-circle"></em>Contact</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="6">
                          <a
                            href="/CRM/ContractListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Contract"
                            ><em class="fa fa-circle"></em>Contract</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="8">
                          <a
                            href="/CRMReport/Index?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="CRM Report"
                            ><em class="fa fa-circle"></em>CRM Report</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_4"
                        data-p-id="4"
                        data-p-name="CRM"
                      >
                        <li class="menuitem" menucount="6" count="1">
                          <a
                            href="/CRM/DealListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Deal"
                            ><em class="fa fa-circle"></em>Deal</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="2">
                          <a
                            href="/CRM/InwardListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Goods Inward"
                            ><em class="fa fa-circle"></em>Goods Inward</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="3">
                          <a
                            href="/CRM/InvoiceListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Invoice"
                            ><em class="fa fa-circle"></em>Invoice</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="4">
                          <a
                            href="/CRM/ItemListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="No"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product"
                            ><em class="fa fa-circle"></em>Product</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="5">
                          <a
                            href="/CRM/ItemTypeListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="No"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Product Type"
                            ><em class="fa fa-circle"></em>Product Type</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="6">
                          <a
                            href="/CRM/Index?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Lead"
                            ><em class="fa fa-circle"></em>Lead</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="7">
                          <a
                            href="/CRM/PriceBookListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Price Book"
                            ><em class="fa fa-circle"></em>Price Book</a
                          >
                        </li>
                      </ul>
                      <ul
                        class="submenu d-none clschild_4"
                        data-p-id="4"
                        data-p-name="CRM"
                      >
                        <li class="menuitem" menucount="6" count="1">
                          <a
                            href="/CRM/PurchaseOrderListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Purchase Order"
                            ><em class="fa fa-circle"></em>Purchase Order</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="2">
                          <a
                            href="/CRM/QuotationListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Quote"
                            ><em class="fa fa-circle"></em>Quote</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="3">
                          <a
                            href="/CRM/SalesOrderListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Sales Order"
                            ><em class="fa fa-circle"></em>Sales Order</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="4">
                          <a
                            href="/CRM/StageListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Stage"
                            ><em class="fa fa-circle"></em>Stage</a
                          >
                        </li>
                        <li class="menuitem" menucount="6" count="5">
                          <a
                            href="/CRM/VendorListing?reqfrom=4"
                            title=""
                            id="cadmin_messageboard_link"
                            checkprivilege="Yes"
                            data-toggle-tooltip="tooltip"
                            data-original-title="Vendor"
                            ><em class="fa fa-circle"></em>Vendor</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="mCSB_1_scrollbar_vertical"
                    class="mCSB_scrollTools mCSB_1_scrollbar mCS-dark-thick mCSB_scrollTools_vertical"
                    style="display: none"
                  >
                    <div class="mCSB_draggerContainer">
                      <div
                        id="mCSB_1_dragger_vertical"
                        class="mCSB_dragger"
                        style="
                          position: absolute;
                          min-height: 30px;
                          top: 0px;
                          height: 0px;
                        "
                      >
                        <div
                          class="mCSB_dragger_bar"
                          style="line-height: 30px"
                        ></div>
                      </div>
                      <div class="mCSB_draggerRail"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <input type="hidden" id="hdnSortExp" value="" />
  <input type="hidden" id="hdnSortBy" value="" />
  <input type="hidden" id="hdnChkMobile" value="no" />
  </div>
</template>
