<script>
import { storeToRefs } from "pinia";
import HeaderUser from "./HeaderCompany.vue";
import HeaderCL from "./HeaderCL.vue";
import { useloggedUserInfo } from "../../stores/NavUserInfo";
// const { GetUserInfo } = storeToRefs(useloggedUserInfo)
export default {  
  name: 'TALYGENHeader',
  components: {
    HeaderUser,
    HeaderCL
  },
  setup() {
    const store = useloggedUserInfo()
    const { GetUserInfo } = storeToRefs(store)
    return {
      GetUserInfo
    }
  },
}
</script>
<template>
  <div class="wrapper" style="min-height: auto">
    <header v-if="GetUserInfo != null">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top switch-menu navbar px-lg-0" >
        <span class="text-white d-none"> {{ GetUserInfo.Name }}</span>
        <HeaderUser v-if="GetUserInfo.UserType == 'CA' || GetUserInfo.UserType == 'PM' || GetUserInfo.UserType == 'NU'" />
        <HeaderCL v-if="GetUserInfo.UserType == 'CL'" />
      </nav>
    </header>
  </div>
</template>

<style>
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.dropdown-menu-right {
    right: 0;
    left: auto !important;
}
</style>
