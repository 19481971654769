
<template>

    <div>
        <loader :is-visible="isLoading"></loader>
        <div class="my-popups">
            <div class="modal d-block ">
                <div class="modal-dialog modal-dialog-centered ui-draggable">
                    <div class="modal-content">
                        <div class="modal-header ui-draggable-handle  py-0">
                            <div class="theme-primary partition-full">
                                <span class="p-name text-white">Manage Form</span>
                            </div>
                            <button type="button" class="close" @click=closepopup()>
                                <em aria-hidden="true" class="fa fa-times"></em>
                            </button>
                        </div>
                        <div class="border p-3 scroll">
                            <dynamic-form ref="manageForm" :buttons="buttons" :schema="FormSchema"></dynamic-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataService from '../../services/DataService';
export default {
    props: {
        getvalues: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            // Id:this.$route.params.id,
            UserName: null,
            UserId: null,
            FormSchema: [],
            modulename: '',
            submodulecode: '',
            buttons: [{
                type: "submit",
                class: "btn btn-success",
                text: "<i class='fa fa-save pr-2'></i>Save",
                onClick: this.Submit
            },
            {
                type: "button",
                class: "btn btn-danger",
                text: "<i class='fa fa-close pr-2'></i>Cancel",
                onClick: this.closepopup,
                isVisible: true,
            },
            ]
        }
    },
    async created() {
        var vm = this;
        vm.UserId = vm.GetUserInfo.ID;
        vm.UserName = vm.GetUserInfo.Name;
        vm.getForm(vm.getvalues);
    },
    methods: {
        closepopup() {
            var vm = this;
            vm.$parent.IsManageForm = false;
        },
        getForm: function (values) {
            
            var ths = this;
            
            ths.isLoading = true;
            ths.modulename = values.module_name;
            ths.submodulecode = values.sub_module_code
            var param = `id=&moduleName=${values.module_name}&subModuleCode=${values.sub_module_code}&otherData=&langCode=en&CustomLayoutId=${values.custom_layout_id}&usefor=QuickAdd`;
            DataService.GetFormCustomLayout(param).then(function (response) {
                
                
                if (response != null && response.data != null) {
                    ths.FormSchema = ths.$refs.manageForm.ConvertCustomFieldIntoDynamicFormObj(response.data.data);
                    ths.isLoading = false;
                }
            });
        },
        Submit () {
            
            var ths = this;
            ths.isLoading = true;
            var CustomFieldArray = ths.$refs.manageForm.ConvertDynamicFormObjToCustomFieldObj(ths.FormSchema);
            var obj = {
                data: CustomFieldArray,
                moduleCode: ths.modulename,
                subModuleCode: ths.submodulecode
            }
            DataService.SaveDynamicForm(JSON.stringify(obj)).then(function (response) {
                
            if (response != null && response.data != null && response.data[0].Status == "Success") {
                ths.ShowAlert('Form save Successfully', "success", true, ths.$t("Alert"));
            }
            })
            ths.isLoading = false;
        }
    }
}
</script>
