<template>
<div>
    <li class="nav-item">
        <a class="nav-link timer-icon d-none forceshow" title="" id="btnTimer" data-toggle-tooltip="tooltip" data-original-title="Timer"><i class="fa fa-clock-o m-0" aria-hidden="true"></i><span class="text-lmenu">Timer</span></a>
        <div id="divtimernewdata">

            <!--Record container starts-->

            <div class="timer_wrap show" id="divTopTimer" v-if="store.getTimer.trackType=='Break'">
                <div class="timer_desc text-left" style="min-width: 100px;">
                    <div class="desc_txt" id="projectNameTimerTop" :title="state.breakTimerShow"> {{ getTimer.breakName }}</div>
                    <div id="TimerCanves" class="DivTimer timer_txt">{{ breakTimerClock}}</div>
                </div>
                <div class="circle stop" @click="stopBreakTime"><a class="pauseTimer" href="javascript:;" title="" id="btnStop" data-toggle-tooltip="tooltip" data-original-title="Stop"><i class="fa fa-stop"></i></a></div>
            </div>
            <div class="timer_wrap show" id="divTopTimer" v-if="store.getTimer.trackType=='TimeTrack'" :class="store.getTimer.trackAgent=='silentapp' ? 'tt_disabled' : ''">
                <div class="timer_desc text-left" style="min-width: 100px;">
                    <div class="desc_txt" id="projectNameTimerTop" :title="getTimer.projectId ? getTimer.projectName : getTimer.trackDescription"> {{ getTimer.projectId ? getTimer.projectName : getTimer.trackDescription }}</div>
                    <div id="TimerCanves" class="DivTimer timer_txt">{{ state.breakTimerShow ? state.breakTimer : getTimerClock }}</div>
                </div>
                <!-- store.getTimer.trackAgent -->
                <!-- :class="store.getTimer.enableBreak!='yes' ? '' : 'd-none'" -->
                <div class="circle pause" :class="store.getTimer.enableBreak!='yes' ? '' : 'd-none'" @click="pauseTimer" v-if="getTimer.statusCode!='PAUS'"><a class="pausetimer pause" title="Pause" data-toggle-tooltip="tooltip"><i class="fa fa-pause"></i></a></div>
                <div disabled class="circle pause" :class="store.getTimer.enableBreak!='yes' ? '' : 'd-none'" @click="resumeTimer" v-else><a class="pausetimer pause" title="Resume" data-toggle-tooltip="tooltip"><i class="fa fa-play"></i></a></div>
                <div disabled class="circle stop" @click="closeTimer"><a href="javascript:;" title="Stop"><i class="fa fa-stop"></i></a></div>
                <div :disabled="true" class="circle" @click="editTimerInfo({modalClose:true})"><a class="updatetimer text-success" href="javascript:;" title="Edit"><i class="fa fa-pencil"></i></a></div>
                <!-- <div class="circle"><a class="text-default" href="javascript:;" title="" id="btnTimerattachment" data-toggle-tooltip="tooltip" data-original-title="Add Attachment"><i class="fa fa-upload"></i></a></div> -->
                <div :disabled="true" class="circle " :class="store.getTimer.enableBreak=='yes' ? '' : 'd-none'" @click="breakTimeClose({breakTimer:false,modalClose:true})"><a class="pausetimer pause" title="Break" data-toggle-tooltip="tooltip" data-original-title="Pause"><i class="fa fa-clock-o text-blue"></i></a></div>

            </div>

        </div>
    </li>

    <Loader :isVisible="getLoadingStatus"></Loader>
    <TimeTracker v-if="state.showTimetrackerModal" v-on:close="state.showTimetrackerModal=false" v-on:openTimer="editTimerInfo" :start="true" :isEdit="true" v-bind:trackDataPouse="trackerData"></TimeTracker>
    <!-- Break time call from timer module then alsways timerModule=true -->
    <BreakTime v-if="state.breakTimerOn" v-on:close="breakTimeClose" v-bind:userId="getUserInformation.id" v-bind:timerModule="true" v-bind:trackDataPouse="trackerData">
    </BreakTime>
</div>
</template>

    
<script>
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    reactive
} from '@vue/composition-api'
import {
    userTimerInfo
} from "../../stores/TimeTracker";
import Loader from "../Loader.vue"
import {
    storeToRefs
} from "pinia";
import moment from "moment"
import {
    formatter,
    getSecondsFromDate,
    trackerDataFormCommen,
    timeToSeconds,
    dateAndTimeZone,
    dateTimeFromat
} from "@/assets/common/vue-common.js"
import TimeTracker from "./TimeTrackerModal.vue";
import BreakTime from "./BreakTime.vue"
import EN from '../../localization/en'
export default defineComponent({
    components: {
        Loader,
        TimeTracker,
        BreakTime,
        i18n: {
            locale: "en",
            messages: {
                en: EN,
            },
        },
    },
    props: {
        breakOrTimerOn: {
            type: Object
        }
    },
    setup(props, context) {

        const store = userTimerInfo();
        const {
            getTimer,
            getLoadingStatus,
            getUserInformation,
            getTimerClock,
            getTimerRunResponse,
            breakTimerClock
        } = storeToRefs(store)
        const state = ref({
            breakTimerShow: false,
            breakTimerPlay: false,
            breakTimerInterval: '',
            breakTimeCount: 0,
            breakTimer: "00:00:00",
            showTimetrackerModal: false,
            breakTimerOn: false,
            error: ''

        })
        const timerPlay = ref(true)
        const trackerData = reactive({
            projectId: 0,
            trackDescription: "",
            taskId: 0,
            isBillable: false,
            format: false,
            date: null,
            trackId: 0,
            dateStart: '',
            startTime: dateAndTimeZone(moment(), dateTimeFromat),
            stopTime: dateAndTimeZone(moment(), dateTimeFromat),
            companyId: null,
            currentDate: dateAndTimeZone(moment(), dateTimeFromat),
            trackAgent: "web",
            timerStatus: "",
            CreatedBy: getUserInformation.value.id,
            createdBy: getUserInformation.value.id,
            linkedTrackId: null,
            totalHours: 0,
            timeformat: "12",
            TagIds: null,
            isForcedUpdate: null,
            type: "TimeTrack",
            BreakDate: dateAndTimeZone(moment(), dateTimeFromat),
            BreakName: null,
            BreakId: 0,
            BreakStartTime: 0,
            BreakStopTime: dateAndTimeZone(moment(), dateTimeFromat),
            Shift_ID: 0,
            SelectedDate: "",
            Break_Shift: "",
        })
        const formdata = ref({})
        Object.assign(formdata.value, trackerData)
        onMounted(() => {
            store.stopTimer()
            store.getBreakTime();
            if (props.breakOrTimerOn) {
                state.value.breakTimerShow = props.breakOrTimerOn.breakTimer
                state.value.breakTimerPlay = props.breakOrTimerOn.breakTimer
            }
            store.$state.timerCountInSec = 0
            store.setTimerClock(store.$state.timerCountInSec);
            //Check break timer and timer start or not if start then start timer
            if (getTimer.value?.statusCode) {
                if (getTimer.value?.trackType == 'Break') {
                    store.$state.breakNegative = true
                    let seconds = getSecondsFromDate({
                        startDate: getTimer.value?.dateStart,
                        endDate: dateAndTimeZone(moment(), dateTimeFromat)
                    }, 'seconds')

                    store.$state.breakTimerCountInSec = timeToSeconds(getTimer.value.remainingBreakDuration) - seconds
                    startBreakTime()
                } else {
                    let countResumeInSeconds = 0
                    console.log("checkk", moment(new Date(store.getTimer?.dateStop), "DD/MM/YYYY HH:mm:ss").format("DD-MM-YYYY"))
                    store.$state.timerCountInSec = moment(new Date(store.getTimer?.dateStop), "DD/MM/YYYY HH:mm:ss").format("DD-MM-YYYY") != "01-01-1950" ?
                        countResumeInSeconds + parseInt(getTimer.value.resumeHours) :
                        getSecondsFromDate({
                            startDate: getTimer.value?.dateStart,
                            endDate: dateAndTimeZone(moment(), dateTimeFromat)
                        }, 'seconds')
                    Object.assign(trackerData, getTimer.value)
                    if (getTimer.value?.statusCode != "ACT") {

                        store.setTimerClock(store.$state.timerCountInSec);
                        trackerData.totalHours = store.$state.timerCountInSec
                        timerPlay.value = false
                    } else {
                        startTimer();

                    }
                }
            }
        })

        function startTimer() {
            store.startTimer(store.$state.timerCountInSec)
            timerPlay.value = true
        }

        function resumeTimer() {
            trackerData.startTime = dateAndTimeZone(moment(), dateTimeFromat)
            trackerData.stopTime = dateAndTimeZone(moment(), dateTimeFromat)
            // trackerData.dateStart = moment().format('YYYY-MM-DD HH:mm:ss')
            submitTime({
                timer: "resume"
            });

        }

        function pauseTimer() {
            trackerData.stopTime = dateAndTimeZone(moment(), dateTimeFromat)
            // trackerData.dateStop = moment().format('YYYY-MM-DD HH:mm:ss')
            trackerData.totalHours = store.$state.timerCountInSec
            submitTime({
                timer: "pause"
            })
        }
        /* Timer Stop and close timer components 
         */
        function closeTimer() {
            /* Clear Interval from store */
            
            trackerData.stopTime = dateAndTimeZone(moment(), dateTimeFromat)
            trackerData.totalHours = store.$state.timerCountInSec
            submitTime({
                timer: "stop"
            })

        }

        async function submitTime(event) {
            trackerData.CreatedBy = getUserInformation.value.id
            trackerData.createdBy = getUserInformation.value.id
            trackerData.trackId = getTimer.value?.trackId
            trackerData.trackDescription = getTimer.value?.trackDescription
            trackerData.timerStatus = event.timer,
                trackerData.projectId = getTimer.value?.projectId
            trackerData.taskId = getTimer.value?.taskId
            await store.submitProjectInfo(trackerData)
            console.log("store.getTimerRunResponse.status",store.getTimerRunResponse.status)
            if (store.getTimerRunResponse.status == 0) {
                if (event.timer == 'pause') {
                    timerPlay.value = false
                    store.stopTimer()
                }
                if (event.timer == 'resume') {
                    startTimer();
                }
                if (event.timer == 'stop') {
                    store.stopTimer()
                    store.$state.timerCountInSec = 0
                    store.setTimerClock("00:00:00")
                   
                }

                await store.getTimerInfo();

            }

            //store.submitTimer(info);
        }

        onUnmounted(() => {
            // submitTime(true)

        })
        /* Start break time */

        function startBreakTime() {
            state.value.breakTimerShow = true
            state.value.breakTimerPlay = true
            store.breakStartTimer()
        }
        async function stopBreakTime() {
            trackerData.CreatedBy = getUserInformation.value.id
            trackerData.createdBy = getUserInformation.value.id
            trackerData.trackId = getTimer.value?.trackId
            trackerData.trackDescription = getTimer.value?.trackDescription
            trackerData.timerStatus = "stop",
                trackerData.type = "Break"
            trackerData.BreakStartTime = getTimer.value.dateStart
            trackerData.BreakStopTime = dateAndTimeZone(moment(), dateTimeFromat)
            let seconds = getSecondsFromDate({
                startDate: getTimer.value?.dateStart,
                endDate: dateAndTimeZone(moment(), dateTimeFromat)
            }, 'seconds')
            trackerData.totalHours = seconds

            await store.submitProjectInfo(trackerData)
            if (store.getTimerRunResponse.status == 0) {
                store.breakStopTimer()
                state.value.breakTimerShow = false
                store.$state.breakTimerCountInSec = 0
                store.$state.timerRunResponse = ''
                Object.assign(trackerData, formdata.value)
                await store.getTimerInfo();
                //console.log("status",)
                if (getTimer.value.statusCode == "PAUS" && getTimer.value.trackType == 'TimeTrack') {
                    store.$state.timerCountInSec = parseInt(getTimer.value.resumeHours)
                    Object.assign(trackerData, getTimer.value)
                    trackerData.type = "TimeTrack"

                    setTimeout(() => {
                        resumeTimer()
                    }, 1000)

                } else {
                    this.$emit("startTimerClose", {
                        isOpen: false,
                        update: false
                    })
                }
            }

        }

        // function resumeBreakTime() {

        //     startBreakTime()

        // }

        // function pauseBreakTime() {
        //     state.value.breakTimerPlay = false
        //     clearInterval(state.value.breakTimerInterval)

        // }

        /* End break time */
        function editTimerInfo(event) {
            state.value.showTimetrackerModal = event.modalClose
            if (event.breakTimer) {
                state.value.breakTimerShow = true
            }
        }

        function breakTimeClose(event) {
            state.value.breakTimerShow = event.breakTimer
            state.value.breakTimerOn = event.modalClose
        }
        return {
            closeTimer,
            pauseTimer,
            startTimer,
            timerPlay,
            getTimer,
            getLoadingStatus,
            state,
            resumeTimer,
            startBreakTime,
            stopBreakTime,
            //resumeBreakTime,
            //pauseBreakTime,
            getTimerClock,
            editTimerInfo,
            breakTimerClock,
            breakTimeClose,
            getUserInformation,
            trackerData,
            store

        }
    },
})
</script>

    
<style scoped>
.select2-container {
    z-index: 99999 !important;
}

.timetrackpadding {
    padding: 0 2px 0 10px;
}
</style>
