<template>
    <div class="my-popups" id="MMMM">
        <!-- The Modal -->
        <div class="modal d-block"  v-on:click="onclick($event)"> <!---id="choosewizards"--->
            <div class="modal-dialog modal-dialog-centered initialpopupbase menuwidgets">
                <div class="modal-content">
                    <!-- <div class="modal-header">
                        <h4 class="modal-title">Wizards</h4>
                        <button type="button" class="close" data-dismiss="modal" @click="CloseHeaderPopup"><i
                                class="fa fa-times" aria-hidden="true"></i></button>
                    </div> -->
                    <!-- Modal body   -->
                    <div class="modal-body">
                        <section class="tab">
                            <div class="tab-style">
                                <div class="tab_container">


                                    <div class="widget_panel">
                                        <ul>
                                            <li>
                                                <a class="card-box screenshot p-0" :title="$t('InitialSetup')">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-user font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>Initial Setup</p>
                                                        </div>
                                                        <span
                                                            class="viewdetails mt-0 font-12 font-weight-medium w-100 text-center">4
                                                            mins...</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="card-box screenshot p-0" :title="$t('HelpAndGuide')"
                                                    @click="HelpGuide">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-book font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>Help &amp; Guide</p>
                                                        </div>

                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="card-box screenshot p-0" :title="$t('ImportSampleData')">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-download font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>Import Sample Data</p>
                                                        </div>

                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="card-box screenshot p-0" :title="$t('StartTourGuide')">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-play font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>Start Tour Guide</p>
                                                        </div>

                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="card-box screenshot p-0" :title="$t('Wizard')"
                                                    @click="showWizard = !showWizard">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-magic font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>{{ $t('Wizard') }}</p>
                                                        </div>

                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="card-box screenshot p-0" :title="$t('Bookmark')">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-star font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>Bookmark</p>
                                                        </div>

                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a id="chat_popup" class="card-box screenshot p-0"
                                                    :title="$t('Communication')" @click="OpenChat">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-comments font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>Communication</p>
                                                        </div>

                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a id="btnDeleteSampleData" :title="$t('Delete')"
                                                    class="card-box screenshot p-0" @click="btnDeleteSample()">
                                                    <div class="card-detail-main detail_bx">
                                                        <div screenshotid="5501"
                                                            class="text-center pb-2 float-left w-100 viewdetails">
                                                            <em class="fa fa-trash font-23"></em>
                                                        </div>
                                                        <div class="time_clk font-12 font-weight-medium w-100 text-center">
                                                            <p>{{ $t('Delete') }}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>


                                        </ul>
                                    </div>


                                    <div class="collapse navbar-collapse" id="navbarResponsive">
                                        <ul class="navbar-nav ml-auto">
                                            <!-- <li class="nav-item m-n-item ">
                    <a id="btnTourSampleData" @click="tourGuide(true)" class="nav-link" title="Tour Guide" href="javascript:;"><i class="fa fa-play m-0" aria-hidden="true"></i><span class="text-lmenu">@Resources.sampleData</span></a>
                </li> -->

                                            <!-- <li class="nav-item m-n-item ">
                                                <a id="btnDeleteSampleData" @click="btnDeleteSample()"
                                                    class="nav-link sampledata" title="Delete Sample Data"
                                                    href="javascript:;"><i class="fa fa-trash m-0"
                                                        aria-hidden="true"></i><span
                                                        class="text-lmenu">sampleData</span></a>
                                            </li>

                                            <li class="nav-item m-n-item wizardnew">
                                                <a class="nav-link" data-toggle="modal" @click="showWizard = !showWizard"
                                                    href="javascript:void(0)" title="Wizard"><span
                                                        class="text-lmenu">{{ $t('Wizard') }}</span></a>
                                            </li> -->
                                            <!-- <li class="nav-item m-n-item"
                                                v-if="!store2.getTimer.trackType && !timeTrackPage">
                                                <a class="nav-link" data-toggle="modal"
                                                    @click="showTimetrackerModal = !showTimetrackerModal"
                                                    href="javascript:void(0)" :title="$t('Clock')"><i
                                                        class="fa fa-clock-o m-0 " style="font-size:20px"
                                                        aria-hidden="true"></i><span
                                                        class="text-lmenu">{{ $t('Clock') }}</span></a>
                                            </li>

                                            <TimerStartOrStop v-else-if="!timeTrackPage && store2.getTimer.trackType"
                                                v-on:startTimerClose="startTimer"
                                                v-bind:breakOrTimerOn="breakOrTimerOnEvent"></TimerStartOrStop> -->
                                            <!-- <li class="nav-item">
                                                <a class="nav-link" :title="$t('FullScreen')" id="btnFullscreen"
                                                    href="javascript:;" @click="toggleFullscreen()">
                                                    <em class="zoom-screen" aria-hidden="true">
                                                        <img class="dark-full-screen" style="height: 1rem; width: 1rem"
                                                            alt="Image" src="/Content/images/iconfinder_fullscreen.png" />
                                                        <img class="light-full-screen"
                                                            style="height: 1rem; width: 1rem; display: none" alt="Image"
                                                            src="/Content/images/iconfinder_fullscreen-light.png" />
                                                    </em>
                                                    <span class="text-lmenu">FullScreen</span>
                                                </a>
                                            </li> -->
                                            <!-- <li class="nav-item">
                                                <a id="chat_popup" class="nav-link" :title="$t('Communication')"
                                                    href="javascript:;" @click="OpenChat">
                                                    <em class="fa fa-commenting-o actionicon_20">
                                                        <small id="popChatCount" class="notification_bell"
                                                            style="display: none"></small>
                                                    </em><span class="bookmark text-lmenu">Communication</span>
                                                </a>
                                            </li> -->
                                            <!-- <li class="nav-item">
                                                <a id="popNotificationLink" class="nav-link" title="Notifications"
                                                    href="javascript:;" @click='slideToggleNotification($event)'>
                                                    <em class="fa fa-bell position-relative" style="font-size: 18px">
                                                        <small id="popNotificationCount"
                                                            class="noti-circle noti-green px-1 position-absolute d-none"
                                                            style="left: 20px; top: -4px"></small>
                                                    </em><span class="bookmark text-lmenu ml-1">Notifications</span>
                                                </a>
                                                <div id="divPopNotificationData"
                                                    class="position-absolute border themecontrol d-none">
                                                    <Unreadnotification v-if="notifications" :notifications="notifications">
                                                    </Unreadnotification>
                                                </div>
                                            </li> -->
                                            <!-- <li class="nav-item">
                                                <a class="nav-link ancbookmark" :title="$t('Bookmark')" href="javascript:;"
                                                    @click="OnShowBookMark">
                                                    <em class="fa fa-star"></em><span
                                                        class="bookmark text-lmenu">Bookmark</span>
                                                </a>
                                            </li> -->
                                            <!-- <li class="nav-item d-none">
                                                <a class="nav-link" :title="$t('Socialize')" href="javascript:;">
                                                    <em class="fa fa-comments"></em>
                                                    <span class="socialize">Socialize</span>
                                                </a>
                                            </li> -->
                                            <!-- <li>
                                                <RouterLink to="/User/Home" class="nav-link sidebaricon"
                                                    checkPrivilege="Yes">
                                                    <span>{{ $t("Dashboard") }}</span>
                                                </RouterLink>
                                            </li> -->
                                            <!-- <li class="nav-item dropdown position-unset">
                                                <a class="nav-link dropdown-toggle sidebaricon position-relative"
                                                    :title="$t('FullMenu')" href="javascript:;" data-toggle="dropdown"
                                                    id="navbarDropdownPortfolio" aria-haspopup="true" aria-expanded="false">
                                                    <em class="fa fa-th-large i-lmenu"></em><span>{{ $t("FullMenu")
                                                    }}</span>
                                                </a>
                                                <div v-bind:class="{ 'custom-width-menu': TotalCount <= 5 }"
                                                    class="dropdown-menu dropdown-menu-right full-menu-new overflow-visible mt-0"
                                                    id="fullMenuUl" aria-labelledby="navbarDropdownPortfolio"
                                                    style="max-height:1000px;">
                                                    <div class="menu-search-panel">
                                                        <input type="text" name="search" v-model="searchMenu"
                                                            @keyup="SeacrchMenuItem($event)" class="menusearchitem"
                                                            placeholder="Type to Search Menu items" />
                                                        <a v-if="searchMenu" class="clear-search" @click="handleCookEggs"
                                                            title="@Resources.Clear"><em class="fa fa-times"></em></a>
                                                    </div>
                                                    <div class="navigation-panel w-100 navigation-panel-full-menu">
                                                        <div class="nav-heading" style="display:none;">
                                                            <span><em
                                                                    class="fa fa-home cursor-pointer text-dark pr-2 ancbacktomenu"></em></span>
                                                            <span><em class="fa  fa-angle-right text-dark pr-2"></em></span>
                                                            <span id="spntext"><em class="fa fa-th-list d-none"></em></span>

                                                            <span class="back ml-auto"><span id="spnAdvancedSetup"
                                                                    v-if="UserType == 'CA'"><em
                                                                        class="fa fa-th-list d-none"></em> </span><a
                                                                    class="cursor-pointer ancbacktomenu" title="Back"><em
                                                                        class="fa fa-chevron-left"></em>Back</a></span>
                                                        </div>
                                                        <perfect-scrollbar>
                                                            <div id="scrollbar" style="max-height:350px!important;">
                                                                <ul v-for="(parent, parentIndex) in MenuDataGroupBy"
                                                                    :key="parentIndex + Math.random()">
                                                                    <li v-for="(item, itemIndex) in parent.Menu"
                                                                        :key="itemIndex + Math.random()"
                                                                        :data-id="item.ModuleMasterId"
                                                                        :data-name="item.ModuleName"
                                                                        :data-url="item.ShowDashboardLink == 0 ? '' : '/' + item.ControllerName + '/' + item.ActionName">

                                                                        <a :class="{ 'mainmenu menuitemsover cursor-pointer': true, 'disabled': item.IsDisabled == 0 }"
                                                                            href="javascript:;"
                                                                            :checkPrivilege="item.RoleApplicable"
                                                                            v-if="(item.ShowDashboardLink == 1)">
                                                                            <em
                                                                                :class="'fa ' + item.ModuleIcon"></em>{{ item.ModuleName }}
                                                                            <span class="fa  fa-angle-right gotosub"
                                                                                @click="GoToSubCategory($event, item.ModuleMasterId)"></span>
                                                                        </a>
                                                                        <a :class="{ 'mainmenu menuitemsover cursor-pointer menudashboard': true }"
                                                                            href="javascript:;"
                                                                            v-if="item.ShowDashboardLink == 0">
                                                                            <em
                                                                                :class="'fa ' + item.ModuleIcon"></em>{{ item.ModuleName }}
                                                                            <span class="fa  fa-angle-right gotosub"
                                                                                @click="GoToSubCategory($event, item.ModuleMasterId)"></span>
                                                                        </a>
                                                                    </li>
                                                                </ul>

                                                                <div class="w-100 float-left"
                                                                    v-for="(parent, parentIndex1) in MenuDataList"
                                                                    :key="parentIndex1 + Math.random()">

                                                                    <ul :class="'submenu d-none clschild_' + parent.ModuleMasterId"
                                                                        :data-p-id="parent.ModuleMasterId"
                                                                        :data-p-name="parent.ModuleName"
                                                                        v-for="(url, urlIndex ) in parent.UrlList"
                                                                        :key="urlIndex + Math.random()">
                                                                        <li v-for="(item, itemIndex) in url.URL"
                                                                            :key="itemIndex + Math.random()"
                                                                            class="menuitem">
                                                                            <RouterLink
                                                                                :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                                                v-if="getRootLink(item) == 'CURRENT'"
                                                                                :to="'/' + item.CONTROLER_NAME + '/' + item.ACTION_NAME + '?reqFrom=' + parent.ModuleMasterId">
                                                                                <em
                                                                                    class="fa fa-circle"></em>{{ ($t(item.DISPLAY_NAME)) }}
                                                                            </RouterLink>
                                                                            <a v-else
                                                                                :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                                                :href="getRootLink(item) + '/' + item.CONTROLER_NAME + '/' + item.ACTION_NAME + '?reqhFrom=' + parent.ModuleMasterId">
                                                                                <em
                                                                                    class="fa fa-circle"></em>{{ ($t(item.DISPLAY_NAME)) }}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </perfect-scrollbar>
                                                    </div>
                                                </div>
                                            </li> -->
                                            <!-- <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle sidebaricon" title="" :href="ProfileUrl"
                                                    id="navbarDropdownMenuLink" data-toggle="dropdown"
                                                    data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false"
                                                    data-toggle-tooltip="tooltip" data-original-title="My Profile">
                                                    <span class="my-image d-none d-lg-block img-lmenu"
                                                        style="line-height: normal">
                                                        <img :src="Avatar" alt="Image"
                                                            onerror="this.src = '/Content/images/DefaultImages/avatar-icon.jpg'"
                                                            loading="lazy" />
                                                    </span>
                                                    <em class="fa fa-user i-lmenu"></em><span>{{ GetUserInfo.Name.split("
                                                                                                            ")[0] }}</span>
                                                </a>
                                                <ul class="dropdown-menu dropdown-menu-right my-account"
                                                    aria-labelledby="navbarDropdownMenuLink"
                                                    data-parent="#navbarDropdownMenuLink">
                                                    <li>
                                                        <a class="dropdown-item" :href="ProfileUrl" title=""
                                                            data-toggle-tooltip="tooltip"
                                                            data-original-title="My Profile"><em
                                                                class="fa fa-id-card"></em>My Profile</a>
                                                    </li>
                                                    <li>
                                                        <input type="hidden" id="hdnUserLanguage" value="en">
                                                        <a class="dropdown-item dropdown-toggle"
                                                            id="dropdownMenuButtonLanguage" data-toggle="dropdown"
                                                            aria-expanded="false" href="javascript:;"><em
                                                                class="fa fa-globe"></em>{{ SelectedLanguage }}</a>
                                                        <vue-scroll class="dropdown-menu selectlanguage"
                                                            aria-labelledby="dropdownMenuButtonLanguage">
                                                            <ul>
                                                                <li v-for="(lang, i) in Languages" :key="i + Math.random()">
                                                                    <a class="dropdown-item" href="javascript:;"
                                                                        @click="SetLanguage(lang.LANGCODE)"
                                                                        :typefor="lang.LANGCODE"><span
                                                                            :class="'flag-icon flag-icon-squared mr-1 flag-icon-' + lang.LANG_ICON"></span>{{
                                                                                lang.LANG_NAME }}</a></li>
                                                            </ul>
                                                        </vue-scroll>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item dropdown-toggle"
                                                            id="dropdownMenuButtonMenuType" href="javascript:;"
                                                            data-toggle="dropdown" aria-expanded="false">
                                                            <em class="fa fa-bars"></em>Menu :<span
                                                                id="spnMenuType">{{ SelectedMenuType }}</span>
                                                        </a>
                                                        <ul class="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButtonMenuType">
                                                            <li>
                                                                <a class="dropdown-item sidebarCollapse" href="javascript:;"
                                                                    @click="SetMenuType('T')">Top</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item sidebarCollapse" href="javascript:;"
                                                                    @click="SetMenuType('L')">Left</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item dropdown-toggle" href="javascript:;">
                                                            <em class="fa fa-table"></em>Theme :
                                                            <span id="spnTheme" valuefor="CssGreen">Default</span>
                                                        </a>
                                                        <ul class="dropdown-menu themeselect">
                                                            <li>
                                                                <a class="dropdown-item" href="javascript:;" title=""
                                                                    valuefor="Colored" data-toggle-tooltip="tooltip"
                                                                    data-original-title="Colored">Colored</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="javascript:;" title=""
                                                                    valuefor="Dark" data-toggle-tooltip="tooltip"
                                                                    data-original-title="Dark">Dark</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="javascript:;" title=""
                                                                    valuefor="Default" data-toggle-tooltip="tooltip"
                                                                    data-original-title="Default">Default</a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" href="javascript:;" title=""
                                                                    valuefor="Light" data-toggle-tooltip="tooltip"
                                                                    data-original-title="Light">Light</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item"
                                                        href="https://app.talygen.com/Notification/Index" title=""
                                                        data-toggle-tooltip="tooltip"
                                                        data-original-title="Notifications"><em
                                                            class="fa fa-bell"></em>Notifications</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item"
                                                        href="https://app.talygen.com/Help/TalygenHelp" title=""
                                                        data-toggle-tooltip="tooltip" data-original-title="Help"><em
                                                            class="fa fa-question"></em>Help</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" id="DashboardPolicy" href="javascript:;"
                                                        title="" data-toggle-tooltip="tooltip"
                                                        data-original-title="Policy"><em
                                                            class="fa fa-wpforms"></em>Policy</a>
                                                </li>
                                                <li>
                                                    <RouterLink to="/logout" class="dropdown-item" checkPrivilege="Yes"
                                                        data-original-title="Logout"><em
                                                            class="fa fa-power-off"></em>Logout</RouterLink>

                                                </li>
                                            </ul>
                                        </li> -->
                                        </ul>
                                    </div>
                                    <input type="hidden" id="hdnSortExp" value="" />
                                    <input type="hidden" id="hdnSortBy" value="" />
                                    <input type="hidden" id="hdnChkMobile" value="no" />
                                    <input type="hidden" id="hdnischildsearch" value="0" />
                                    <!-- <input type="hidden" id="hdnChatUrl" value=" /mychat/" /> -->
                                    <!-- <TourGuide v-if="tourGuideShow" v-on:close="tourGuideShow=false"/> -->
                                    <!-- <Choosewizards v-if="showWizard" v-on:close="showWizard=false"></Choosewizards> -->

                                    <QuickAddPopup v-if="showWizard" v-on:close="showWizard = false"></QuickAddPopup>
                                    <!-- <TimeTracker v-if="showTimetrackerModal" v-on:close="showTimetrackerModal=false"
                                    v-on:openTimer="timerStart" :start="true"></TimeTracker> -->

                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// const modal = document.getElementById('choosewizards');
// window.onclick = function() {
//     debugger
//     $("#MMMM").addClass('d-none');

//   }


import $ from "jquery";
import {
    storeToRefs
} from "pinia";
import {
    useloggedUserInfo
} from "../../stores/NavUserInfo";
const currentAppRootURL = process.env.VUE_APP_CURRENT_SITE_ROOT_URL;
const defaultAppURL = process.env.VUE_APP_DEFAULT_APP_URL;
const fullMenuRedirectionApplied = process.env.VUE_APP_FULL_MENU_APP_REDIRECTION;
import DataService from "../../services/DataService"
//import Unreadnotification from "../Notification/Unreadnotification.vue";
//import Choosewizards from "../Wizard/choosewizards.vue";

import QuickAddPopup from "../Wizard/QuickAddPopup.vue";

import EN from '../../localization/en'
//import TimeTracker from "../Tracker/TimeTrackerModal.vue";
//import TimerStartOrStop from '../Tracker/TimerStartOrStop.vue';

//import TourGuide from "@/components/TourGuide/TourGuide.vue";
import {
    userTimerInfo
} from "../../stores/TimeTracker";
export default {
    components: {
        QuickAddPopup,
        //Unreadnotification,
        //Choosewizards,
        //TimeTracker,
        //TimerStartOrStop,            
        ///TourGuide
    },
    i18n: {
        locale: "en",
        messages: {
            en: EN,
        },
    },
    data() {
        return {
            MenuData: [],
            MenuDataList: [],
            MaxLoop: 5,
            ModuleName: "",
            MenuDataGroupBy: [],
            MenuCountmodule: 0,
            TotalCount: 0,
            TotalMenuCount: 0,
            Languages: [],
            SelectedLanguage: "English",
            SelectedMenuType: "Top",
            notifications: null,
            showWizard: false,
            showBookmark: false,
            CompanyLogo: null,
            Avatar: null,
            ProfileUrl: null,
            UserType: null,
            showTimetrackerModal: false,
            startTimerShow: false,
            timeTrackPage: false,
            //tourGuideShow:false,
            breakOrTimerOnEvent: { "breakTimer": false },
            searchMenu: '',

        };
    },
    setup() {
        const store = useloggedUserInfo()
        const store2 = userTimerInfo()
        const {
            getTimer
        } = storeToRefs(store2)
        const {
            GetUserInfo

        } = storeToRefs(store);
        return {
            GetUserInfo,
            getTimer,
            store2
        };
    },

    created: async function () {

        window.onbeforeunload = this.closeComponent;
        this.CompanyLogo = this.GetUserInfo.CompanyLogo;
        this.UserType = this.GetUserInfo.UserType;
        this.Avatar = this.GetUserInfo.Avatar;
        this.Avatar = this.Avatar ? this.Avatar : '/Content/images/DefaultImages/avatar-icon.jpg';
        if (!this.CompanyLogo || this.CompanyLogo.toLowerCase() == "company-logo.jpg") {
            this.CompanyLogo = '/Content/images/DefaultImages/company-logo.jpg';
        } else {
            await this.GetCompanyLogo(this.CompanyLogo);
        }
        this.ProfileUrl = defaultAppURL + '/MyProfile/Index?companyid=' + this.GetUserInfo.CompanyId;
        this.GetFULLMenuData();
        this.GetLanguageList();
        await this.store2.getTimerInfo();
        //this.GetTimer();
    },
    watch: {
        '$route.path': {
            handler: async function (search) {
                console.log("test", this.$route)
                this.timeTrackPage = false
                let url = `/Timer/Index`
                //console.log("check",search.toLowerCase(),url.toLowerCase())
                if (search.toLowerCase().localeCompare(url.toLowerCase()) != 0) {
                    this.GetTimer();
                    this.timeTrackPage = false
                } else {
                    // console.log("second",search)
                    this.timeTrackPage = true
                }
                if (this.$route.query.reload == 'reload') {
                    this.timeTrackPage = true
                    const query = Object.assign({}, this.$route.query);
                    delete query.reload;
                    await this.store2.getTimerInfo();
                    this.$router.push({ query: query })
                    this.timeTrackPage = false
                }
            },
            deep: true,
            immediate: true
        }
    },


    methods: {

        // closemodal(){
        //     // $("#MMMM").addClass('d-none');
        //     this.$parent.IsHeaderShow = false;
        // },
        onclick: function(ths){
            if(ths.currentTarget == ths.target){
                this.$parent.IsHeaderShow = false;
            }
        },
        HelpGuide: function () {
            debugger
            this.$router.push({ path: '/Help/HelpAndGuide' });
            this.$parent.IsHeaderShow = false;
        },

        closePopup: function () {
            this.$parent.IsHeaderShow = false;
        },
        OpenChat: function () {
            this.OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348', 800, 1200, false);
        },
        OpenWindow: function (query, h, w, scroll) {
            if (isMobile == true) {
                localStorage.removeItem('clientOpen');
            }

            var screenWidth = window.innerWidth * 0.7;
            var screenHeight = window.innerHeight * 0.8;

            var l = (window.innerWidth - screenWidth) / 2;
            var t = (window.innerHeight - screenHeight) / 3;

            var winprops = 'height=' + screenHeight + ',width=' + screenWidth + ',top=' + t + ',resizable=0,status=no,menubar=no,toolbar=no,location=no,addressbar=no,left=' + l;
            if (scroll) winprops += ',scrollbars=1';

            var stateClient = localStorage.getItem('clientOpen'); //window.localStorage.getItem

            if (stateClient == "1") {
                console.log(" localStorage.removeItem('clientOpen');");
                if (window.navigator.userAgent.indexOf("Edge") > -1) // because edge does not have local object global
                {
                    chatlogwindow = window.open(query, "_blank", winprops);
                }
            } else {
                if (isMobile == true) {
                    chatlogwindow = window.open(query, "_self", winprops);
                } else {
                    //   localStorage.setItem('clientOpen', '1');
                    chatlogwindow = window.open(query, "_blank", winprops);
                }
            }
        },
        CloseHeaderPopup: function () {
            this.$parent.IsHeaderShow = false;
        },

        async GetTimer() {


            if (this.getTimer?.trackId) {
                this.startTimerShow = true
            } else {
                this.startTimerShow = false
            }



        },
        // tourGuide(event){
        //     this.tourGuideShow = event

        // },
        handleCookEggs(event) {

            event.stopPropagation(); // USED HERE!
            this.searchMenu = ''
        },
        closeComponent() {
            this.startTimerShow = false
        },
        timerStart(event) {
            //breakTimer for timer and breack show in header
            this.breakOrTimerOnEvent = { "breakTimer": event.breakTimer }
            this.startTimerShow = true
            this.showTimetrackerModal = false
        },
        startTimer(e) {
            this.startTimerShow = e?.isOpen
            this.showTimetrackerModal = e?.update
        },
        OnShowBookMark() {
            const customEvent = new CustomEvent('event_addbookmark')
            window.dispatchEvent(customEvent);
        },
        toggleFullscreen(elem) {
            elem = elem || document.documentElement;
            if (!document.fullscreenElement && !document.mozFullScreenElement &&
                !document.webkitFullscreenElement && !document.msFullscreenElement) {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        },
        getRootLink: function (link) {
            var rtnValue = "";
            if (fullMenuRedirectionApplied == "true") {
                if (link) {
                    if (link.ROOT_URL != null && link.ROOT_URL.toLowerCase() == currentAppRootURL.toLowerCase()) {
                        rtnValue = "CURRENT";
                    } else if (link.ROOT_URL != null) {
                        rtnValue = link.ROOT_URL;
                    } else {
                        rtnValue = defaultAppURL;
                    }
                }
            } else {
                rtnValue = "CURRENT";
            }
            return rtnValue;
        },
        SetMenuType(type) {
            if (type == "L") {
                this.SelectedMenuType = "Left";
                $("body").addClass("menu-left-wrapper wrapper-shrink");
            } else {
                this.SelectedMenuType = "Top";
                $("body").removeClass("menu-left-wrapper wrapper-shrink");
            }
        },
        SetLanguage(code) {
            //v-model="$i18n.locale"
            this.$i18n.locale = code;
            var chkGroup = $.grep(this.Languages, function (element) {
                return element.LANGCODE == code;
            });
            this.SelectedLanguage = chkGroup[0].LANGNAME;
        },
        async GetLanguageList() {
            var vm = this;
            DataService.GetLanguageList().then((response) => {
                if (response.data.status == "Success") {
                    vm.Languages = response.data.result.DATA;
                    vm.getUnReadNotifications();
                }
            });
        },
        async GetCompanyLogo(imageName) {
            var vm = this;
            var param = 'imagename=' + imageName;
            await DataService.GetCompanyLogo(param).then((response) => {
                vm.CompanyLogo = response.data;
            });
        },
        async GetFULLMenuData() {
            var vm = this;
            DataService.GetFullMenu().then((response) => {
                if (response.data.status == "Success") {
                    vm.MenuData = response.data.result.DATA;
                    var MenuGroupData = [];
                    if (vm.MenuData != null && vm.MenuData.length > 0) {
                        var groupId = vm.MenuData[0].MODULE_MASTER_ID;
                        vm.MenuData.forEach(item => {
                            groupId = item.MODULE_MASTER_ID;
                            var chkGroup = $.grep(MenuGroupData, function (element) {
                                return element.ModuleMasterId == groupId;
                            });
                            if (chkGroup.length == 0) {
                                var returnedData = $.grep(vm.MenuData, function (element) {
                                    return element.MODULE_MASTER_ID == groupId;
                                });
                                var chkDashBoard = $.grep(returnedData, function (element) {
                                    return String(element.ACTION_NAME).toLowerCase() == "dashboard";
                                });
                                var urlCount = returnedData.length;
                                var groupCount = Math.ceil(urlCount / 3);
                                var urlListData = [];
                                var urlIndex = 0;
                                returnedData.forEach(url => {
                                    var chkInsert = false;
                                    if (urlIndex == groupCount) {
                                        urlIndex = 0;
                                        chkInsert = true;
                                        var obj = {
                                            URL: []
                                        };
                                        obj.URL.push(url);
                                        urlListData.push(obj);
                                    }
                                    if (!chkInsert) {
                                        var length = urlListData.length;
                                        if (length == 0) {
                                            var obj1 = {
                                                URL: []
                                            };
                                            obj1.URL.push(url);
                                            urlListData.push(obj1);
                                        } else {
                                            urlListData[length - 1].URL.push(url);
                                        }
                                    }
                                    urlIndex += 1;
                                });
                                if (returnedData.length > 0) {
                                    var objGroup = {
                                        ModuleMasterId: groupId,
                                        ModuleName: returnedData[0].MODULE_HEADER_NAME,
                                        ModuleIcon: returnedData[0].ATTACHMENT_PATH == "" ? "fa-cog" : returnedData[0].ATTACHMENT_PATH,
                                        ShowDashboardLink: chkDashBoard.length > 0 ? 1 : 0,
                                        ControllerName: chkDashBoard.length > 0 ? chkDashBoard[0].CONTROLER_NAME : "",
                                        ActionName: chkDashBoard.length > 0 ? chkDashBoard[0].ACTION_NAME : "",
                                        RoleApplicable: chkDashBoard.length > 0 ? chkDashBoard[0].ROLE_APPLICABLE : "",
                                        IsDisabled: chkDashBoard.length > 0 ? chkDashBoard[0].IS_DISABLED : "0",
                                        UrlList: urlListData
                                    };
                                    MenuGroupData.push(objGroup);
                                }
                            }
                        });
                        this.TotalCount = MenuGroupData.length;
                        this.TotalMenuCount = Math.ceil(this.TotalCount / 3);
                        this.MenuCountmodule = this.TotalCount % 3;
                        this.MenuDataList = MenuGroupData;
                        console.log(this.MenuDataList);
                        var chkMenuCount = 1;
                        var count = 1;
                        this.MenuDataGroupBy = [];
                        MenuGroupData.forEach(item => {
                            var countMenu = vm.TotalMenuCount;
                            if ((vm.MenuCountmodule <= 2 && chkMenuCount == 1) || (vm.MenuCountmodule == 2 && chkMenuCount == 2)) {
                                countMenu = countMenu + 1;
                            }
                            var chkInsert = false;
                            if (count == countMenu) {
                                count = 0;
                                chkMenuCount += 1;
                                if (chkMenuCount <= 3) {
                                    chkInsert = true;
                                    var obj = {
                                        Menu: []
                                    };
                                    obj.Menu.push(item);
                                    vm.MenuDataGroupBy.push(obj);
                                }
                            }
                            if (!chkInsert) {
                                var length = vm.MenuDataGroupBy.length;
                                if (length == 0) {
                                    var obj2 = {
                                        Menu: []
                                    };
                                    obj2.Menu.push(item);
                                    vm.MenuDataGroupBy.push(obj2);
                                } else {
                                    vm.MenuDataGroupBy[length - 1].Menu.push(item);
                                }
                            }
                            count += 1;
                        });
                        setTimeout(function () {
                            vm.SetScrolbar("dark-thick", "", "navigation-panel-full-menu");
                        }, 1500);
                    }
                }
            });
        },
        GoToSubCategory(event, id) {
            var $this = $(event.target);
            $("#hdnischildsearch").val("1");
            ShowChild($this, false, id);
            $("#fullMenuUl").addClass("show");
        },
        SeacrchMenuItem(event) {
            var val = event.target.value;
            if (val != "") {
                $("a.clear-search").show();
            } else {
                $("a.clear-search").hide();
            }
            SearchMenuItem(val);
        },
        getUnReadNotifications() {
            var vm = this;
            DataService.UnreadItems("reqFrom=count").then(function (response) {
                vm.notifications = response.data;
            });
        },
        btnDeleteSample() {
            var vm = this;
            vm.confirmR(vm.$t("ConfirmSampleDataDelete"), true, (vm.$t("Delete")), function () {

                DataService.DeleteSampleData().then(function (response) {

                    if (response.data.msg == "success") {
                        vm.ShowAlert(vm.$t("SampleDataDeleted"), "success", true, vm.$t("Alert"));
                    } else {
                        vm.ShowAlert(vm.$t("Something_Wrong"), "failure", true, vm.$t("Alert"));
                    }
                })

            })
        }
    },
}

var timerOut;
var open_window = 0;
var chatlogwindow;
var isMobile;
var socket;
$(document).ready(function () {
    ! function (a) {
        var b = /iPhone/i,
            c = /iPod/i,
            d = /iPad/i,
            e = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i,
            f = /Android/i,
            g = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,
            h = /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,
            i = /IEMobile/i,
            j = /(?=.*\bWindows\b)(?=.*\bARM\b)/i,
            k = /BlackBerry/i,
            l = /BB10/i,
            m = /Opera Mini/i,
            n = /(CriOS|Chrome)(?=.*\bMobile\b)/i,
            o = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,
            p = new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)", "i"),
            q = function (a, b) {
                return a.test(b)
            },
            r = function (a) {
                var r = a || navigator.userAgent,
                    s = r.split("[FBAN");
                return "undefined" != typeof s[1] && (r = s[0]), s = r.split("Twitter"), "undefined" != typeof s[1] && (r = s[0]), this.apple = {
                    phone: q(b, r),
                    ipod: q(c, r),
                    tablet: !q(b, r) && q(d, r),
                    device: q(b, r) || q(c, r) || q(d, r)
                }, this.amazon = {
                    phone: q(g, r),
                    tablet: !q(g, r) && q(h, r),
                    device: q(g, r) || q(h, r)
                }, this.android = {
                    phone: q(g, r) || q(e, r),
                    tablet: !q(g, r) && !q(e, r) && (q(h, r) || q(f, r)),
                    device: q(g, r) || q(h, r) || q(e, r) || q(f, r)
                }, this.windows = {
                    phone: q(i, r),
                    tablet: q(j, r),
                    device: q(i, r) || q(j, r)
                }, this.other = {
                    blackberry: q(k, r),
                    blackberry10: q(l, r),
                    opera: q(m, r),
                    firefox: q(o, r),
                    chrome: q(n, r),
                    device: q(k, r) || q(l, r) || q(m, r) || q(o, r) || q(n, r)
                }, this.seven_inch = q(p, r), this.any = this.apple.device || this.android.device || this.windows.device || this.other.device || this.seven_inch, this.phone = this.apple.phone || this.android.phone || this.windows.phone, this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet, "undefined" == typeof window ? this : void 0
            },
            s = function () {
                var a = new r;
                return a.Class = r, a
            };
        "undefined" != typeof module && module.exports && "undefined" == typeof window ? module.exports = r : "undefined" != typeof module && module.exports && "undefined" != typeof window ? module.exports = s() : "function" == typeof define && define.amd ? define("isMobile", [], a.isMobile = s()) : a.isMobile = s()
    }(this);
    isMobile = window.orientation > -1;

    function socketCall() {
        socket.emit('user notification', user);
        $("#popChatNotification").fadeIn(1000).fadeOut(1000);
    }

    $("body").on("click", ".videocall,.chat,.voicecall", function (event) {
        var clickID = $(event.currentTarget).attr('id');
        var ticketRaised = $("#hdnUserId").val();
        if (user.userId == ticketRaised) {
            $("#chat_popup").trigger("click");
        } else if (clickID == "call") {
            OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348&call=1&tid=' + ticketRaised, 800, 1200, false);
        } else {
            OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348&tid=' + ticketRaised, 800, 1200, false);
        }

    });

    var chkIsOpen = true;

    $('#chat_popup').click(function () {
        debugger
        OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348', 800, 1200, false);

    });


    var count = 0;

    function getUserDetails() {
        var user = new Object();
        var mobileType = window.orientation > -1;
        var type = "web";
        if (mobileType) {
            type = "mobile";
        }
        user.type = type;
        user.userId = $("#UserId").val();
        user.name = $("#hdnName").val();
        user.userimgPath = $("#hdnloginUserImgPath").val();
        if (typeof user.userimgPath !== 'undefined') {
            if (user.userimgPath.length <= 0) {
                user.userimgPath = "/Content/images/DefaultImages/avatar-icon.jpg";
            }
        }
        user.companyId = $("#cid").val();
        user.Email = $("#hdnloggedinemailid").val();
        return user;
    }

    function bellDetails(count) {
        if (count > 0) {
            $("#popChatCount").show();
            notifyMeChat(count);
        }
        if (count == 0) {
            $("#popChatCount").hide();
        }
        if (count > 99) {
            count = 99;
        }
        $('#popChatCount').html(count);
    }
    var user = getUserDetails();

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    function notifyMeChat(data) {
        $.notifyClose();
        var vm = this;
        var body = "You have received a new Message (" + data + ")";
        var icon = '';
        var title = vm.$t("TALYGEN") + ":" + vm.$t("UnifiedCommunication");
        var duration = 10000;
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            displayNotificationChat(body, icon, title, duration, data);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    displayNotificationChat(body, icon, title, duration, data);
                }
            });
        }
    }

    function notifyMe(data, type) {
        // Let's check if the browser supports notifications
        var body = "You have received a video call from " + replaceAll(data.fromName, "%20", " ");
        var vm = this;
        var icon = data.fromUserPath;
        var title = vm.$t("TALYGEN") + ":" + vm.$t("UnifiedCommunication");
        var linkAccept = "joinVideoCall";
        var duration = 50000;

        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            // var notification = new Notification($(body));
            displayNotification(body, icon, title, linkAccept, duration, data, type);
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    displayNotification(body, icon, title, linkAccept, duration, data, type);
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }

    function displayNotification(body, icon, title, linkAccept, duration, data, type) {
        duration = duration || 10000; // Default duration is 5 seconds
        var open_window1 = 0;

        var options = {
            icon: icon,
            body: body
        };

        var n = new Notification(title, options);

        if (linkAccept) {
            console.log("html5 click");
        };
        n.onclick = function () {
            var isMobile = window.orientation > -1;
            if (isMobile == true) {
                localStorage.removeItem('clientOpen');
                chatlogwindow.close();
            }

            var stateClient = localStorage.getItem('clientOpen');

            if (stateClient == "1") {
                var newparam = new Object();
                if (type == "talygen") {
                    newparam.room = data.roomId + '~' + data.actRoom + '~' + data.actRoomId;
                    newparam.receiver = data.fromUserId;
                    newparam.contactname = data.fromName;
                    newparam.id = user.userId;
                    socket.emit('add video on chat', newparam);
                } else {
                    newparam.path = data.actRoomId + '~' + data.actRoom + '~' + data.data.sessionId + '~' + data.data.apiKey,
                        newparam.receiver = receiverId_accept;
                    newparam.contactname = contactname_accept;
                    newparam.id = user.userId;
                    socket.emit('add video on chat tokBox', newparam);
                }
                n.close();
                $('#' + data.fromUserId + ".vca_mainwrapper").remove();
            } else {
                if (type == "talygen")
                    callWindow(data.roomId + '~' + data.actRoom + '~' + data.actRoomId, data.fromUserId, data.fromName, type);
                else
                    callWindow(data.actRoomId + '~' + data.actRoom + '~' + data.data.sessionId + '~' + data.data.apiKey, data.fromUserId, data.fromName, type);

                n.close();
                $('#' + data.fromUserId + ".vca_mainwrapper").remove();
            }

            function callWindow(room, receiverId, contactname) {
                if (open_window1 == 0) {
                    open_window1++;
                    if (type == "talygen")
                        var alink1 = "/Communication/Index?reqhFrom=40348&room=" + room + "&rid=" + receiverId + "&name=" + contactname;
                    else
                        var alink1 = "/Communication/Index?reqhFrom=40348&path=" + room + "&rid=" + receiverId + "&name=" + contactname;

                    OpenWindow(alink1, 800, 1200, false);
                }
            }
        }

        setTimeout(n.close.bind(n), duration);
    }

    function displayNotificationChat(body, icon, title, duration, data) {
        duration = duration || 10000; // Default duration is 5 seconds
        var options = {
            body: body
        };
        var n = new Notification(title, options);
        setTimeout(n.close.bind(n), duration);
    }
});
$("body").on("click", ".gotosub", function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("1");
    ShowChild($(this));
});
$(".menudashboard").click(function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("1");
    ShowChild($(this));
});

function ShowChild(ctrl, isSearch, moduleId) {
    var id = ctrl.closest("li").attr("data-id");
    if (moduleId) {
        id = moduleId;
    }
    if (typeof (id) != 'undefined') {
        if (isSearch != true) {
            var dataUrl = ctrl.closest("li").attr("data-url") === undefined ? "javascript:;" : ctrl.closest("li").attr("data-url");
            var dataPrivilege = ctrl.closest("li").attr("data-privilege") === undefined ? "0" : ctrl.closest("li").attr("data-privilege");
            var dataUrlcheck = ctrl.closest("li").attr("data-url");
            if (dataUrl == '/File/Dashboard') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvancedSetup" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Asset/Dashboard') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvancedSetups/AdvanceSetting" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Ticketing/Dashboard' || dataUrl == '/Dashboard/Ticketing') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/Ticketing/AdvancedSetup" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<span></span>');
            }

            if (dataUrlcheck !== undefined) {
                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '<a href="' + dataUrl + '" checkPrivilege="' + dataPrivilege + '" class="text-info indl">|<em class="fa fa-laptop"></em>Dashboard</a></span>');
            }

            if (dataUrlcheck !== undefined && dataUrlcheck != "") {
                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '<a href="' + dataUrl + '" checkPrivilege="' + dataPrivilege + '" class="text-info indl">|<em class="fa fa-laptop"></em>Dashboard</a></span>');
            }
            else {

                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '</span>');
            }
        }
        $("div.navigation-panel").find('a.mainmenu').closest("li").hide();
        $("div.navigation-panel").find('ul.submenu').addClass('d-none').removeClass('d-flex');
        $("div.navigation-panel").find('ul.clschild_' + id).addClass('d-flex').removeClass("d-none");
        $("div.navigation-panel").find('ul.clschild_' + id).find("li").show();
        $("div.navigation-panel").find('.hdrGroupName').addClass('d-none');
        $("div.navigation-panel").find('.hdrGroupName_' + id).addClass('d-flex').removeClass("d-none");
    }
}
$("body").on("click", ".ancbacktomenu", function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("0");
    $("div.navigation-panel").find('.nav-heading').hide();
    $("div.navigation-panel").find('.hdrGroupName').addClass('d-none').removeClass("d-flex");
    $("div.navigation-panel").find('ul.submenu').addClass('d-none').removeClass('d-flex');
    $("div.navigation-panel").find('a.mainmenu').closest("li").show();
    $("input.menusearchitem").val('');
    //SearchMenuItems($("input.menusearchitem").val());
    $("#fullMenuUl").find("ul:not(.submenu)").find("li.menuitem").show();
});



function OpenWindow(query, h, w, scroll) {
    debugger
    if (isMobile == true) {
        localStorage.removeItem('clientOpen');
    }

    var screenWidth = window.innerWidth * 0.7;
    var screenHeight = window.innerHeight * 0.8;

    var l = (window.innerWidth - screenWidth) / 2;
    var t = (window.innerHeight - screenHeight) / 3;

    var winprops = 'height=' + screenHeight + ',width=' + screenWidth + ',top=' + t + ',resizable=0,status=no,menubar=no,toolbar=no,location=no,addressbar=no,left=' + l;
    if (scroll) winprops += ',scrollbars=1';

    var stateClient = localStorage.getItem('clientOpen'); //window.localStorage.getItem

    if (stateClient == "1") {
        console.log(" localStorage.removeItem('clientOpen');");
        if (window.navigator.userAgent.indexOf("Edge") > -1) // because edge does not have local object global
        {
            chatlogwindow = window.open(query, "_blank", winprops);
        }
    } else {
        if (isMobile == true) {
            chatlogwindow = window.open(query, "_self", winprops);
        } else {
            //   localStorage.setItem('clientOpen', '1');
            chatlogwindow = window.open(query, "_blank", winprops);
        }
    }
}

function SearchMenuItem(val) {
    $("#fullMenuUl ul.submenu").find("li.menuitem a").each(function () {
        val = String(val).toLowerCase();
        var text = String($(this).text()).toLowerCase();
        if (text.includes(val)) {
            $(this).closest("li.menuitem").show();
        } else {
            $(this).closest("li.menuitem").hide();
        }
        $("div.navigation-panel").find('a.mainmenu').closest("li").hide();
        var chk = false;
        $(this).closest('.submenu').find("li.menuitem").each(function () {
            if ($(this).css('display') != 'none') {
                chk = true;
            }
        });
        if (chk) {
            $(this).closest('.submenu').removeClass('d-none').addClass('d-flex');
        } else {
            $(this).closest('.submenu').addClass('d-none').removeClass('d-flex');
        }
    });
    if ($("input.menusearchitem").val() == '' && $("#hdnischildsearch").val("0")) {
        $(".ancbacktomenu").trigger('click');
    }
}
</script>
    
<style scoped>.ps {
    max-height: 350px;
}</style>