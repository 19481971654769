import Vue from "vue";
import "./set-public-path";
import singleSpaVue from "single-spa-vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from 'vue-i18n';
import EN from "./localization/en";
import { createPinia, PiniaVuePlugin } from 'pinia';
import TALYGENHeader from '../src/components/Header/Header.vue';
import Loader from './components/Loader.vue'
import { useloggedUserInfo } from "./stores/NavUserInfo";
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import commonMixin from '../src/assets/common/vue-common.js'	
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
import moment from 'moment';
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { ValidationProvider } from 'vee-validate';
Vue.config.productionTip = false;
Vue.mixin(commonMixin, ListingcommonMixin)
Vue.use(vuescroll);
Vue.use(VueI18n);
Vue.use(PerfectScrollbar)
Vue.use(PiniaVuePlugin)
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('dynamic-form',DynamicForm);
Vue.component('TALYGENHeader',TALYGENHeader);
Vue.component('loader', Loader);
Vue.prototype.$vuescrollConfig = {
  bar: {
    background: '#929292'
  }
};
const pinia=createPinia();
window.pinia = pinia;
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en:EN,
  },
});
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
Vue.filter('formatTime', function (value){
  if (value) {
    return moment(value, "HH:mm:ss").format(String('h:mm A'));
}
})
const containerSelector = '#nav-bar-1-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
   router,
    render(h) {
      return h(App, { 
         props: {          
         userProfile:useloggedUserInfo().setUser(this.userProfile) 
        } 
      });//
    },
    el: containerSelector
  }
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

