<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo} from "./stores/NavUserInfo";
export default {
  setup(){     
    const store = useloggedUserInfo();
		const { GetUserInfo } = storeToRefs(store)
    setTimeout(() => {      
      // $("#divProgress1").css('display','none')
      // $("#talygenfooter").css('display','block')
      if(window.location.pathname=='/'){
        window.location.href='/User/Home';
      }      
    }, 1000);
		return {GetUserInfo }
  }, 
}
</script>
<template>
    <section>
        <TALYGENHeader v-if="GetUserInfo != null" />
        
    </section>    
</template>
<style>
/* @import "@/assets/base.css"; */

@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}
</style>
