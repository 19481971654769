<template>
    <section>
    <div>
        <div class="my-popups">
            <div class="modal d-block ">
                <div class="modal-dialog modal-dialog-centered ui-draggable">
                    <div class="modal-content">
                        <div class="modal-header ui-draggable-handle  py-0">
                            <button type="button" class="close" @click=ClosePopup()>
                                <em aria-hidden="true" class="fa fa-times"></em>
                            </button>
                            <h4 name="header" class="modal-title py-3">
                                Get Quick Add List
                            </h4>
                        </div>
                        <div class="container-fluid padding-t_10 d-block">
                            <div class="row new_tab">
                                <form class="w-100 p-4">
                                    <div v-for="(item, index) in Quicklistdata" :key="index"
                                        class="collapse show mt-2 col-md-4">
                                        <div class="btn-group btn-group-toggle custom-reports-icons my-2 w-100"
                                            data-toggle="buttons" v-on:click="getForm(item)" :key="item.sub_module_id">
                                            <label
                                                v-bind:class="{ 'btn btn_cstm': true, 'active': (item.sub_module_id == selectedModuleId) }"
                                                v-bind:for="'cls_usertype' + index">
                                                <input type="radio" v-bind:value="item.sub_module_id" name="cls_usertype"
                                                    v-bind:id="'cls_usertype' + index" v-model="selectedModuleId" />
                                                <span class="d-block pb-2"><em class="fa fa-plus bg-transparent"
                                                        aria-hidden="true"></em></span>
                                                 {{ $t(item.sub_module_name) }}
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <GetQuickAddLayoutForm v-if="IsManageForm" :getvalues="getvalues"></GetQuickAddLayoutForm>
</section>
</template>
<script>
import DataService from '../../services/DataService';
//import GetQuickAddLayoutForm from "../HelpAndGuide/GetQuickAddLayoutForm.vue";
import GetQuickAddLayoutForm from "../Wizard/GetQuickAddLayoutForm.vue";

export default {
    // props: {
    //     LayoutListingData: {
    //         type: Array,
    //         required: true
    //     }
    // },
     components: {
        GetQuickAddLayoutForm,
    },
    data() {
        return {
            //isCollapsed: false,
            isLoading: false,
            Quicklistdata: [],
            // moduledata:[],
            // manageform:false,
            //getAlllayoutData:[],
            getvalues: {},
            selectedModuleId: null,
            IsManageForm: false,
        }
    },
    created() {
        var vm = this;
        vm.getQuickAddData();
    },
    methods: {
        getForm(value) {
            
            var vm = this;
            vm.IsManageForm = true;
            
            vm.getvalues = value;
        },
        getQuickAddData() {
            
            var vm = this;
            DataService.GetQuickAddDataListing().then((response) => {
                
                vm.Quicklistdata = response.data.data;
            })
        },
        ClosePopup:function(){   
                         
                this.$parent.showWizard=false;  
            },
       
    }

}
</script>